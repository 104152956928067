// Core
import React from 'react'
import {Col, Row} from "reactstrap"
import clsx from "clsx"
// import {useTranslation} from "react-i18next"
// Components
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Last5Transactions, WalletDetailsTable} from '@metronic/Components/Tables'
import {MyWalletDetailsWidget} from "@metronic/Components/CustomWidgets"

import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"

const MyWalletContent = () => {
  // const { t } = useTranslation()

  return (
    <>
      <Toolbar/>
      <Content>
        <Row>
          <Col className={clsx(VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-9' : 'col-12 col-xl-9')}>
            <WalletDetailsTable />
          </Col>

          <Col className={clsx(VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-3' : 'col-12 col-xl-3')}>
            <MyWalletDetailsWidget />
          </Col>
        </Row>

        <Row className="pt-5">
          <Col className="col-12">
            <Last5Transactions />
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default MyWalletContent

import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row} from "reactstrap"
import DepositDetailsModal from "@metronic/Components/Modal/DepositDetailsModal"
import {ModalContext} from "@metronic/Components/Modal"
import {toast} from "react-toastify";
import {useForm, usePage} from "@inertiajs/react"
import {selectListData} from "@metronic/api/general.js"

const DepositCryptoForm = () => {
  const [response, setResponse] = useState('')
  const [assets, setAssets] = useState([])
  const [amount, setAmount] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [totalUsdAmount, setTotalUsdAmount] = useState('')
  const [accountBalance, setAccountBalance] = useState([])
  const [selectedAsset, setSelectedAsset] = useState('')
  const {openModal} = useContext(ModalContext)
  const {props} = usePage()

  const {data, setData, post, processing, errors, reset, setDefaults} = useForm({
    asset_id: '',
    amount: '',
    total_usd: '',
  })

  const amountChangeHandler = (e) => {
    let val = e.target.value

    setAmount(val)
    calculateTotalUsdAmount(val)
  }

  const totalUsdChangeHandler = (e) => {
    let val = e.target.value

    setTotalUsdAmount(val)
    setData('total_usd', val)

    calculateAmount(val)
  }

  const currencyChangeHandler = (e) => {
    let val = e.target.options[e.target.selectedIndex].value

    setSelectedCurrency(val)
    setData('asset_id', val)
  };

  const openDepositDetailsModal = () => {
    openModal({
      content: <DepositDetailsModal/>
    })
  }

  const calculateTotalUsdAmount = (newAmount) => {
    assets.forEach((option) => {
      if (option.value === Number(selectedCurrency) && newAmount > 0) {
        const newTotalUsd = (newAmount * Number(option.price)).toFixed(2) * 1
        setTotalUsdAmount(newTotalUsd)
        setData('total_usd', newTotalUsd)
      } else if (newAmount <= 0) {
        setData('total_usd', '')
        setTotalUsdAmount('')
      }
    })
  }

  const calculateAmount = (newTotalUsdAmount) => {
    assets.forEach((option) => {
      if (option.value === Number(selectedCurrency) && newTotalUsdAmount > 0) {
        const newAmount = (newTotalUsdAmount / Number(option.price)).toFixed(8) * 1
        setAmount(newAmount)
        setData('amount', newAmount)
      } else if (newTotalUsdAmount <= 0) {
        setData('amount', '')
        setAmount('')
      }
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    if (!data.asset_id) {
      toast.error('Please select asset')
      return
    } else if (!data.amount) {
      toast.error('Please enter amount')
      return
    } else if (!data.total_usd) {
      toast.error('Please enter total usd')
      return
    }

    post('/deposit/crypto-deposit', {
      preserveScroll: true,
    })

    // openDepositDetailsModal()
  }

  useEffect(() => {
    for (let error in errors) {
      toast.error(errors[error])
    }
  }, [errors])

  useEffect(() => {
    selectListData('crypto_list').then(r => setAssets(r))
    setAccountBalance(props.auth.user_balance)
  }, [])

  useEffect(() => {
    if (assets?.length) {
      setData(data => ({...data, asset_id: assets[0]?.value}));
      setSelectedCurrency(assets[0]?.value)
    }
  }, [assets])

  useEffect(() => {
    calculateTotalUsdAmount(amount)
  }, [amount, selectedCurrency])

  return (
    <>
      <div className="card bg-body">
        <div className="card-header flex-nowrap card-p-0">
          <h3 className="card-title d-flex justify-content-center w-100">
            <div className="card-label fw-bold fs-3 text-gray-700">
              Crypto Deposit
            </div>
          </h3>
        </div>

        {!response ?
          <div className="card-body">
            <Form onSubmit={submitHandler}>
              <div className="input-group mb-6">
                <span className="input-group-text fs-4 w-80px w-sm-25">Currency</span>
                <select
                  name="asset_id"
                  className="form-select select-currency"
                  onChange={(e) => currencyChangeHandler(e)}
                >
                  {assets.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group mb-6">
                <span className="input-group-text fs-4 w-80px w-sm-25">Amount</span>
                <Input
                  type="number"
                  step="any"
                  name="amount"
                  className="form-control"
                  placeholder="0.00000000"
                  autoComplete="off"
                  onChange={(e) => {
                    amountChangeHandler(e)
                    setData('amount', Number(e.target.value))
                  }}
                  value={amount}
                />
              </div>
              <div className="input-group mb-6">
                <span className="input-group-text fs-4 w-80px w-sm-25">Total</span>
                <Input
                  type="number"
                  step="any"
                  name="total_usd"
                  className="form-control"
                  placeholder="0.00000000"
                  autoComplete="off"
                  min="50"
                  max="1000000000"
                  value={totalUsdAmount}
                  onInput={totalUsdChangeHandler}
                />
                <span className="input-group-text justify-content-center">USD</span>
              </div>
              <div className="text-center pt-5">
                <Button
                  type="submit"
                  // disabled={isButtonDisabled}
                  className="btn btn-light-success border border-success border-dashed py-2 fs-5 px-5 w-75"
                >
                  DEPOSIT
                </Button>
              </div>
            </Form>
          </div> :
          <div className="card-body">
            <Row className="w-100">
              <Col xs={12} className="d-flex">
                <div className="d-flex flex-column align-items-center w-100">
                  <div className="avatar-xl mt-2 mb-4 mx-auto">
                    <div className="avatar-title bg-light text-danger rounded-circle"
                         style={{fontSize: '108px'}}>
                      <i className="mdi mdi-close-circle"></i>
                    </div>
                  </div>
                  {/*<h4>{t(`${response.title}`)}</h4>*/}
                  {/*<span className="text-center" dangerouslySetInnerHTML={{__html: t(`${response.text}`)}}></span>*/}
                  <div className="d-flex gap-3 mt-4 justify-content-center w-50">
                    <button
                      type="button"
                      className="btn btn-soft-success nexttab fs-16 w-100"
                      // onClick={() => {
                      //   setResponse('')
                      //   setAmount('')
                      // }}
                    >
                      NEW DEPOSIT
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        }
      </div>
    </>
  )
}

export default DepositCryptoForm

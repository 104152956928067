import React, {createContext, useContext, useEffect, useState} from 'react'
import {Toolbar} from '@metronic/_metronic/layout/components/toolbar/Toolbar'
import {Content} from '@metronic/_metronic/layout/components/Content'
import {Col, Row} from "reactstrap"
import TransactionsTable from "@metronic/Components/Transactions/TransactionsTable.jsx"
import {usePage} from "@inertiajs/react"
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import {
  TransactionsModalContext
} from '@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx'

const TransactionsContent = () => {
  const {openModal} = useContext(ModalContext)
  const {props: pageProps} = usePage()
  const {openTransferDetailsModal} = useContext(TransactionsModalContext)

  useEffect(() => {
    if (pageProps?.active_transfer) {
      openTransferDetailsModal(pageProps.active_transfer)
    }
  }, [pageProps])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className="col-12">
            <TransactionsTable/>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default TransactionsContent

import React from 'react'
import {TwoFactorContent} from '@metronic/Components'

const TwoFactor = () => {
  return (
    <>
      <TwoFactorContent />
    </>
  )
}

export default TwoFactor

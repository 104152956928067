import React, {useEffect, useState} from 'react'
import {Link} from "@inertiajs/react"
import {clsx} from "clsx"
// Context
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {TABS} from '../FakeData.jsx'

const GeneralTabs = () => {
  const {page_data, activeTab, setActiveTab} = useSecuritiesContext()
  const [tabList, setTabList] = useState([])

  useEffect(() => {
    if (page_data && page_data?.id) {
      setTabList(JSON.parse(page_data.tabs))
    }
  }, [page_data])

  return (
    <>
      {/*{(tabList && tabList.length) ?*/}
      {TABS ?
        <>
          <div className={clsx('h-auto d-flex flex-column flex-sm-row',
          VITE_APP_VARIANT === 'light' ? 'justify-content-lg-center justify-content-xl-start' : ''
          )}>
            {
              // tabList.map((tab, idx) => {
              TABS.map((tab, idx) => {
                let tabId = `tab_${idx + 1}`

                return (
                  <ul key={idx} className='nav nav-tabs nav-pills border-transparent fs-6 fw-bolder flex-nowrap w-100 w-sm-auto'>
                    <li className='nav-item w-100 w-sm-auto'>
                      <Link
                        onClick={() => setActiveTab(tabId)}
                        className={clsx('nav-link me-6 text-center', activeTab === tabId && 'active',
                          VITE_APP_VARIANT === 'light' ? 'fs-lg-7 fs-xl-6' : ''
                        )}
                        data-bs-toggle="tab"
                      >
                        {tab.title}
                      </Link>
                    </li>
                  </ul>
                )
              })
            }
          </div>
          <div className="separator mb-5 pt-5"></div>
        </> : null
      }
    </>
  )
}

export default GeneralTabs

import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {WithdrawalBankTransferContent} from "@metronic/Components"

const WithdrawalBankTransfer = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Withdrawal Bank Transfer</PageTitle>
      <WithdrawalBankTransferContent />
    </>
  )
}

export default WithdrawalBankTransfer

import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap"
import {KTIcon} from "@metronic/_metronic/helpers"
import {Link, router, usePage} from '@inertiajs/react'
import {useTranslation} from "react-i18next"
import {Tooltip} from "react-tooltip"
import {clsx} from "clsx"

const NotificationDropdown = () => {
  const [isDropdown, setIsDropdown] = useState(false)
  const [notificationsList, setNotificationsList] = useState([])
  const notifications = usePage().props.auth.notifications.data
  const { t } = useTranslation()
  const [isMouseOnItem, setIsMouseOnItem] = useState(String())

  const toggleDropdown = () => {
    setIsDropdown(!isDropdown)
  }

  useEffect(() => {
    setNotificationsList(notifications)
  }, [notifications])

  const markAsRead = (ids) => {
    router.post('/notifications/toggle', {
      notification_ids: ids
    })
  }

  const marksAllAsRead = () => {
    router.post('/notifications/read-all')
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isDropdown}
        toggle={toggleDropdown}
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon"
        >
          <KTIcon iconName='message-text-2' className="fs-1"/>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-lg py-0">
          <Card className="crad-p-0 w-325px bg-light">
            <div className="px-3 py-3 d-flex align-items-center bg-light-primary justify-content-between">
              <div className="fs-3 text-gray-700">Notifications</div>
              <Button
                type="button"
                color="primary"
                onClick={marksAllAsRead}
                className="btn btn-sm btn-light-primary fw-bold text-nowrap ms-5"
              >
                Marks all as read
              </Button>
            </div>
            <div className='separator d-flex flex-center'></div>

            <div style={{maxHeight: "300px"}} className="scroll-y overflow-y-auto">

              {notificationsList.length > 0 ?
                notificationsList.map((notification, idx) =>
                  <div
                    className={clsx(
                      // 'text-reset',
                      // 'notification-item',
                      'd-block',
                      'dropdown-item',
                      'position-relative',
                      'cursor-default bg-body',
                      !!notification.read_at ? 'bg-light-primary' : '',
                      isMouseOnItem === String(idx) ? 'bg-light-secondary' : ''
                    )}
                    key={idx}
                    onMouseEnter={() => setIsMouseOnItem(String(idx))}
                    onMouseLeave={() => setIsMouseOnItem('')}
                  >
                    <div className="d-flex justify-content-between px-3 py-3" style={{maxWidth: '325px'}}>
                      <div>
                        <div className="me-3">
                          {
                            notification.data.icon ?
                              <>
                            <span
                              className={`h-25px w-25px bg-light-${notification.data.color ? notification.data.color : 'danger'}
                            text-${notification.data.color ? notification.data.color : 'danger'} rounded-circle fs-2x`}>
                              <i className={`${notification.data.icon}`}></i>
                            </span>
                              </> :
                              <span className="h-25px w-25px bg-light-danger text-danger rounded-circle fs-9">
                            <i className='bi bi-chat-left-dots'></i>
                          </span>
                          }
                        </div>
                        <div className="flex-1">
                          <span>
                            <h6 className="mt-0 mb-1 fs-13 lh-base text-gray-700">
                              {t(notification.data.title, {tr_id: notification.data.tr_id})}
                            </h6>
                            <div className="fs-13 text-muted">
                              <p className="mb-2 text-wrap fw-bold">
                                {/*{notification.data?.sub_title}*/}
                                {t(notification.data?.sub_title,
                                  {
                                    tr_id: notification.data.tr_id,
                                    tr_status: t(notification.data.tr_status),
                                    sold_asset: notification.data.sold_asset,
                                    buy_asset: notification.data.buy_asset,
                                    doc_type: notification.data.doc_type,
                                    invoice_id: notification.data.invoice_id
                                  }
                                )}
                              </p>
                            </div>
                          </span>
                          <p className="mb-0 fs-9 fw-bold text-uppercase text-gray-600">
                            <span><i className="bi bi-clock text-gray-600 fs-9"></i> {notification.past_time}</span>
                          </p>
                        </div>
                      </div>
                      <div className="pt-2">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input cursor-pointer"
                            type="checkbox"
                            checked={!!notification.read_at}
                            onChange={() => markAsRead([notification.id])}
                            data-tooltip-id="mark_as_read"
                            data-tooltip-content={t("Mark as read")}
                          />
                          <label className="form-check-label"></label>
                          <Tooltip id="mark_as_read"/>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                : <div className="fw-bold text-center my-3">{t("All notifications is read!")}</div>}

              <div className="d-flex justify-content-center py-5">
                <Link
                  as="button"
                  type="button"
                  href='/notifications'
                  className="btn p-1 w-50 btn-sm btn-light-success hover-scale border border-success fw-bold"
                  onClick={() => setIsDropdown(!isDropdown)}
                >
                  View all notifications
                </Link>
              </div>

            </div>

            {/*<CardBody className="px-3 py-4">*/}
            {/*  {(notificationsList && notificationsList.length) ?*/}
            {/*    notificationsList.map((item, idx) => {*/}
            {/*      return (*/}
            {/*        <div key={idx} className="border rounded-2 p-3 bg-light-success"></div>*/}
            {/*      )*/}
            {/*    }) :*/}
            {/*    <div className="text-gray-700 text-center fw-bold p-3 fs-5">You don't have any notifications yet...</div>*/}
            {/*  }*/}
            {/*</CardBody>*/}

          </Card>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown

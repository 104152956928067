import React from "react";
import {useLayout} from '../../core'
import {Toolbar1Default} from './Toolbar1Default'
import {Toolbar1Light} from './Toolbar1Light'
import {VITE_APP_VARIANT} from '@metronic/helpers/utils'

const Toolbar = () => {
  const {config} = useLayout()

  const toolbarVariations = () => {
    if (VITE_APP_VARIANT === 'default') {
      return <Toolbar1Default />
    } else if (VITE_APP_VARIANT === 'light') {
      return <Toolbar1Light />
    } else {
      return <Toolbar1Default />
    }
  }

  switch (config.toolbar.layout) {
    case 'toolbar1':
      return toolbarVariations()

    default:
      return <Toolbar1Default />
  }
}

export {Toolbar}

import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {BankDetailsContent} from '@metronic/Components'

const BankDetails = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Bank Details</PageTitle>
      <BankDetailsContent />
    </>
  )
}

export default BankDetails

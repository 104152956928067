import React from 'react'
import {Col, Row} from "reactstrap"
import {KTIcon} from "@metronic/_metronic/helpers"
import {usePage} from "@inertiajs/react"

import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import clsx from "clsx";

const MyWalletDetailsWidget = () => {
  const {props} = usePage()

  return (
    <>
      <Row>
        <Col className={clsx(
          VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-12 col-xl-4 col-xxl-12 pt-5 pt-md-6 pt-xxl-0' :
            'col-12 col-sm-4 col-xl-12 pt-5 pt-xl-0'
        )}>
          <div className="card border-0">
            <div className='card-body p-0'>
              <div className='d-flex flex-stack align-items-start pt-5 pb-6 px-4 flex-grow-1'>
                <div className='d-flex flex-column '>
                  <span className='text-gray-800 fs-4 fw-bold'>My Wallet</span>
                  <span className='text-gray-700 fw-bold mt-2 fs-1 text-nowrap'>
                    ${Intl.NumberFormat().format(props.account_balance.total.split('.')[0])}
                    <span className='text-muted fs-3'>.{props.account_balance.total.split('.')[1]}</span>
                  </span>
                </div>
                <KTIcon iconName='wallet' className='fs-2x text-primary'/>
              </div>
            </div>
          </div>
        </Col>

        <Col className={clsx(
          VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-6 col-xl-4 col-xxl-12 pt-6' :
            'col-12 col-sm-4 col-xl-12 pt-6'
        )}>
          <div className="card border-0">
            <div className='card-body p-0'>
              <div className='d-flex flex-stack align-items-start pt-5 pb-6 px-4  flex-grow-1'>
                <div className='d-flex flex-column'>
                  <span className='text-gray-800 fs-4 fw-bold'>Available Balance</span>
                  <span className='text-gray-700 fw-bold mt-2 fs-1 text-nowrap'>
                    ${Intl.NumberFormat().format(props.account_balance.available.split('.')[0])}
                    <span className='text-muted fs-3'>.{props.account_balance.available.split('.')[1]}</span>
                  </span>
                </div>
                <KTIcon iconName='chart-simple-2' className='fs-2x text-success'/>
              </div>
            </div>
          </div>
        </Col>

        <Col className={clsx(
          VITE_APP_VARIANT === 'light' ? 'col-12 col-sm-4 col-lg-6 col-xl-4 col-xxl-12 pt-6' :
            'col-12 col-sm-4 col-xl-12 pt-6'
        )}>
          <div className="card border-0">
            <div className='d-flex flex-stack align-items-start pt-5 pb-6 px-4 flex-grow-1'>
              <div className='d-flex flex-column'>
                <span className='text-gray-800 fs-4 fw-bold'>Locked Balance</span>
                <span className='text-gray-700 fw-bold mt-2 fs-1 text-nowrap'>
                  ${Intl.NumberFormat().format(props.account_balance.locked.split('.')[0])}
                  <span className='text-muted fs-3'>.{props.account_balance.locked.split('.')[1]}</span>
                </span>
              </div>
              <KTIcon iconName='chart-simple-3' className='fs-2x text-danger'/>
            </div>
          </div>
        </Col>

      </Row>
    </>
  )
}

export default MyWalletDetailsWidget

import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {ProfileContent} from '@metronic/Components'
import {TabProvider} from "@metronic/Components/Profile/TabContext/TabContext.jsx"

const Profile = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>My Profile</PageTitle>
      <TabProvider>
        <ProfileContent />
      </TabProvider>
    </>
  )
}

export default Profile

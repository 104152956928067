import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {CreateTicketContent} from "@metronic/Components/index.jsx"

const CreateNewTicket = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Support</PageTitle>
      <CreateTicketContent />
    </>
  )
}

export default CreateNewTicket

import React, { createContext, useContext, useState } from 'react'

const SecuritiesContext = createContext({})

export const useSecuritiesContext = () => {
  return useContext(SecuritiesContext)
}

export const SecuritiesProvider = ({ children, ...props }) => {
  const [activeTab, setActiveTab] = useState('tab_1')
  const [securitiesList, setSecuritiesList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedSecurities, setSelectedSecurities] = useState({})

  return (
    <SecuritiesContext.Provider
      value={{
        ...props,
        activeTab,
        setActiveTab,
        securitiesList,
        setSecuritiesList,
        currentPage,
        setCurrentPage,
        selectedSecurities,
        setSelectedSecurities
    }}>
      {children}
    </SecuritiesContext.Provider>
  )
}

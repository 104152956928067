import { createRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react'
import {MainLight, MainDefault} from "@metronic/Pages/MainVariations"
import './i18n'

const withoutLayout = ['LogIn', 'TwoFactor']
const VITE_APP_VARIANT = import.meta.env.VITE_APP_VARIANT

//Styles

if (VITE_APP_VARIANT === 'light') {
  import ('@metronic/_metronic/assets/sass/style_react/style.react.light.scss')
} else {
  import ('@metronic/_metronic/assets/sass/style_react/style.react.default.scss')
}

import '@metronic/_metronic/assets/fonticon/fonticon.scss'
import '@metronic/_metronic/assets/keenicons/duotone/style.css'
import '@metronic/_metronic/assets/keenicons/outline/style.css'
import '@metronic/_metronic/assets/keenicons/solid/style.css'

if (VITE_APP_VARIANT === 'light') {
  import ('@metronic/_metronic/assets/sass/style/style.light.scss')
} else {
  import ('@metronic/_metronic/assets/sass/style/style.default.scss')
}

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('./Pages/**/*.jsx', { eager: true })
    let page = pages[`./Pages/${name}.jsx`]

    if (!page || !page.default) {
      throw new Error(`Page ${name} does not have a default export.`)
    }

    if (withoutLayout.includes(name)) {
      return page
    }

    switch(VITE_APP_VARIANT) {
      case 'light':
        page.default.layout = page.default.layout || ((page) => <MainLight children={page} />)
        break
      default:
        page.default.layout = page.default.layout || ((page) => <MainDefault children={page} />)
        break
    }

    return page
  },
  setup({ el, App, props }) {
    const root = createRoot(el)
    root.render(<App {...props} />)
  },
  progress: {
    color: '#4B5563',
  },
})

import React from "react"
import {PageTitle} from "@metronic/_metronic/layout/core"
import {DashboardContent} from "@metronic/Components"

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardContent/>
    </>
  )
}

export default DashboardWrapper

import {post} from "@metronic/helpers/api_helper.jsx";

export const calculate = (data) => {
	return post('/exchange/calculate', data)
}

// export const calculateMaxSum = (data) => {
// 	return api.post('/spa/exchange/max-sum/calculate', data)
// }

// export const exchange = (data) => {
// 	return api.post('/spa/exchange', data)
// }

import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core/index.ts"
import {InvoiceContent} from "@metronic/Components/index.jsx"

const Invoices = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Invoices</PageTitle>
      <InvoiceContent />
    </>
  )
}

export default Invoices

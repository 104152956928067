import React from 'react'
import {LogInContent} from "@metronic/Components"

const LogIn = (props) => {
  console.log(props);
  return (
    <>
      <LogInContent />
    </>
  )
}

export default LogIn

import {get} from "@metronic/helpers/api_helper.jsx"

export function selectListData(name) {
	return get('/select-list-data', {name: name})
}

export function staticData(name, args) {
  let data = args ? {name: name, args: args} : {name: name}

	// return api.post('/spa/static-data', data)
}

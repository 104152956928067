import React from "react"
import {MenuItem} from '../MenuItem'
import {MenuInnerWithSub} from "../MenuInnerWithSub"
// import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {MegaMenu} from './MegaMenu'
// import {useIntl} from 'react-intl'

export function MenuInnerDefault() {
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES
  // const intl = useIntl()
  return (
    <>

        <MenuItem title="Dashboard" to='dashboard' />
        <MenuItem title='Exchange' to='exchange' />

        <MenuInnerWithSub
            title='Deposit'
            to='#'
            // icon='element-11'
            // hasArrow={true}
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
        >
            <MenuItem to='deposit.credit-card' title='Credit Card' hasBullet={true} />
            <MenuItem to='deposit.crypto' title='Crypto' hasBullet={true} />
            <MenuItem to='deposit.bank-details' title='Bank Details' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
            title='Withdrawal'
            to='#'
            // icon='element-11'
            // hasArrow={true}
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
        >
            <MenuItem to='withdrawal.crypto' title='Crypto' hasBullet={true} />
            <MenuItem to='withdrawal.bank-transfer' title='Bank Transfer' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuItem to='transactions' title="Transactions" />
        <MenuItem to='invoices.index' title='Invoices' />
        <MenuItem to='support' title='Support' />
        {/*<MenuItem to='securities.index' title='Securities' />*/}
      {(showSecurities === 'true')  && <MenuItem to="securities.index" title="Securities" />}
    </>
  )
}

import React, {useEffect, useState} from 'react'
import {cryptoIconsMapper} from "@metronic/helpers/utils"
import {KTIcon} from "@metronic/_metronic/helpers"
import {usePage} from "@inertiajs/react"
import _ from "lodash"

const WalletDetailsTable = () => {
  const {props} = usePage()
  const [rows, setRows] = useState([])

  const prepareRows = () => {
    const data = {}
    const accountBalance = props.account_balance.all

    _.each(accountBalance, (i) => {
      data[i.name] = _.mergeWith(data[i.name], i, (objValue, srcValue, key) => {
        if (['balance'].includes(key) && objValue && srcValue) {
          return _.add(Number(objValue), Number(srcValue)).toFixed(8);
        }
        if (['usd_amount'].includes(key) && objValue && srcValue) {
          return _.add(Number(objValue), Number(srcValue)).toFixed(2);
        }
      })
    })

    accountBalance.forEach(i => {
      if (i?.is_real) {
        data[i.name]['available'] = i.balance
        data[i.name]['available_usd_amount'] = i.usd_amount
      } else {
        data[i.name]['locked'] = i.balance
        data[i.name]['locked_usd_amount'] = i.usd_amount
      }
    })

    setRows(_.values(data))
  }

  useEffect(() => {
    if (_.size(props?.account_balance)) {
      prepareRows()
    }
  }, [props])

  return (
    <>
      <div className="card min-h-100">
        <div className="card-header border-0 pt-5">
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label text-gray-700 fw-bold fs-3 mb-1'>Wallet Details</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
              <tr className='fw-bold fs-5 text-gray-700 bg-light'>
                <th className='ps-4 min-w-150px rounded-start'>Asset</th>
                <th className='min-w-150px'>Total</th>
                <th className='min-w-150px'>Locked</th>
                <th className='min-w-150px'>Available</th>
                <th className='min-w-150px'>Total in USD</th>
                {/*<th className='min-w-100px rounded-end'>Change %</th>*/}
              </tr>
              </thead>

              <tbody>
              {rows && rows.map((i, idx) => {
                return (
                  <tr key={idx}>
                    <td className="py-1 m-1">
                      <div className="d-flex ms-3 align-items-center">
                        <div className='symbol-label' style={{height: '25px'}}>
                          <img
                            src={cryptoIconsMapper[i.code]}
                            className='h-75 align-self-end'
                            alt=''
                          />
                        </div>
                        <span className="ms-2 pb-1 fw-bold">{i.name}</span>
                      </div>
                    </td>
                    <td className="py-1 m-1">
                      <span className='fw-bold d-block fs-5'>{i.balance}</span>
                      <span className='text-muted fw-semibold d-block fs-7'>{i.usd_amount} USD</span>
                    </td>
                    <td className="py-1 m-1">
                      <span className='fw-bold d-block fs-5'>{i.locked ?? '0.00'}</span>
                      <span className='text-muted fw-semibold d-block fs-7'>{i.locked_usd_amount ?? '0.00'} USD</span>
                    </td>
                    <td className="py-1 m-1">
                      <span className='fw-bold d-block fs-5'>{i.available ?? '0.00'}</span>
                      <span className='text-muted fw-semibold d-block fs-7'>{i.available_usd_amount ?? '0.00'} USD</span>
                    </td>
                    <td className="py-1 m-1">
                      <span className='fw-bold d-block fs-5'>${i.usd_amount}</span>
                    </td>
                    {/*<td className="py-1 m-1">*/}
                    {/*  <div className="d-flex align-items-center">*/}
                    {/*    <KTIcon iconName='arrow-up-refraction'*/}
                    {/*            className='text-success pb-1 fs-2x ms-n1'/>*/}
                    {/*    <span className="text-success ps-2">0.25%</span>*/}
                    {/*  </div>*/}
                    {/*</td>*/}
                  </tr>
                )
              })}
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default WalletDetailsTable

import {toast} from "react-toastify"
import moment from "moment-timezone"
import React from "react"
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap"
import {useTranslation} from "react-i18next"
import classnames from "classnames"
import {Tooltip} from "react-tooltip"
import {capitalize} from "lodash"

// Import Crypto-SVG
import btc from "../assets/images/svg/crypto-icons/btc.svg"
import eth from "../assets/images/svg/crypto-icons/eth.svg"
import ltc from "../assets/images/svg/crypto-icons/ltc.svg"
import dash from "../assets/images/svg/crypto-icons/dash.svg"
import usdt from "../assets/images/svg/crypto-icons/usdt.svg"
import usd from "../assets/images/svg/crypto-icons/usd.svg"
import eur from "../assets/images/svg/crypto-icons/eur.svg"
import gbp from "../assets/images/svg/crypto-icons/gbp.svg"
import xrp from "../assets/images/svg/crypto-icons/xrp.svg"
import ada from "../assets/images/svg/crypto-icons/ada.svg"
import doge from "../assets/images/svg/crypto-icons/doge.svg"
import cdn from "../assets/images/svg/crypto-icons/cdn.svg"
import tusd from "../assets/images/svg/crypto-icons/tusd.svg"

export const VITE_APP_VARIANT = import.meta.env.VITE_APP_VARIANT ? import.meta.env.VITE_APP_VARIANT : 'default'

export function copyToClipboard(data) {
	if (typeof data !== 'string') data = ''
	navigator.clipboard.writeText(data)

  toast.success('Copied to clipboard!')

	// if (localStorage.getItem('I18N_LANGUAGE') === 'en') {
  //   toast.success('Copied to clipboard!')
  // } else {
  //   toast.success('Скопировано в буфер обмена!')
  // }
}

export function toDateFormat(date) {
	return moment(date).format('YYYY-MM-DD')
}

export const twoPrecisionAssets = [
	'USD',
	'EUR',
	'USDT',
	'GBP',
  'CAD',
  'TUSD'
]

export function toTwoPrecision(val) {
	return (Number(val) || 0).toFixed(2)
}

export function isTwoPrecision(assetCode, val) {
	let amount = parseFloat(val)

	if (twoPrecisionAssets.includes(String(assetCode).toUpperCase())) {
    amount = amount.toFixed(2)
  } else {
    amount = amount.toFixed(8).replace(/0+$/, "")
  }

	if (Number.isInteger(parseFloat(val))) {
		return Number(amount).toFixed(2)
	}

  if (!val) return '0.00'

	return amount
}

export const cryptoIconsMapper = {
  BTC: btc,
  ETH: eth,
  LTC: ltc,
  DASH: dash,
  USDT: usdt,
  USD: usd,
  EUR: eur,
  GBP: gbp,
  XRP: xrp,
  ADA: ada,
  DOGE: doge,
  CAD: cdn,
  TUSD: tusd
}

export const timeZone = import.meta.env.VITE_APP_TIMEZONE ? import.meta.env.VITE_APP_TIMEZONE : 'UTC'

export function validateFloatValue(val) {
	const dotInput = val.replace(/,/g, '.')

	return /^\d*\.?\d*$/.test(dotInput) ? dotInput : false;
}

export function prettyDate(date, withTime = true) {
  moment.locale(localStorage.getItem('I18N_LANGUAGE'))

  if (localStorage.getItem('I18N_LANGUAGE') === 'ru') {
    moment.updateLocale('ru', {
      monthsShort : [
        "Янв", "Февр", "Мар", "Апр", "Мая", "Июня",
        "Июля", "Авг", "Сент", "Окт", "Нояб", "Дек"
      ]
    })
  }

  date = moment.tz(date, 'UTC').format()

  return (
    <span className="text-nowrap">
			<span>{moment.tz(date, timeZone).format(`DD MMM, yyyy`)}</span>
      {withTime && (
        <span className="ms-1 text-muted fs-12">{moment.tz(date, timeZone).format('HH:mm:ss')}</span>
      )}
		</span>
  )
}

export function printTransactionType(serverType) {
  switch (serverType) {
    case 'acquisition': {
      return 'deposit'
    }
    case 'deduction': {
      return 'withdrawal'
    }
    case 'invoice': {
      return 'invoice'
    }
    default: {
      return serverType
    }
	}
}

export function copyChatMessage({text, title}) {
  return (
    <UncontrolledDropdown className="align-self-start message-box-drop">
      <DropdownToggle
        href="#"
        className="btn nav-btn"
        tag="a"
      >
        <i className="bi bi-three-dots-vertical"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          href="#"
          onClick={() => copyToClipboard(text)}
          className="d-flex align-items-center"
        >
          <i className="bi bi-copy me-2 text-muted align-bottom"></i>
          <span>{title}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export const TransactionBadgeIcon = ({transactionId, transactionType, invoiceId, invoiceAmount}) => {
  const {t} = useTranslation()

  const tooltipeId = String(transactionId)
  const tooltypeContent =  String(capitalize(printTransactionType(invoiceId && Number(invoiceAmount) < 0 ? 'invoice' :
    transactionType)))

  return (
    <>
      <div
        className="h-25px w-25px rounded-circle d-flex justify-content-center align-items-center"
        data-tooltip-id={tooltipeId}
        data-tooltip-content={tooltypeContent}
      >
        <i className={classnames({
          'bi bi-arrow-up-right-circle text-danger fs-2': transactionType === 'deduction' && !invoiceId,
          'bi bi-arrow-down-left-circle text-success fs-2': transactionType === 'acquisition',
          'bi bi-arrow-repeat text-info fs-2': transactionType === 'exchange' && !invoiceId,
          'bi bi-file-earmark-medical text-warning fs-2': invoiceId && Number(invoiceAmount) < 0
        })}></i>
      </div>
      <Tooltip id={tooltipeId}/>
    </>
  )
}



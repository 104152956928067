import React, {useEffect} from 'react'
import {Suspense} from 'react'
import {AuthInit} from '@metronic/modules/auth'
import {LayoutProvider, LayoutSplashScreen} from '@metronic/_metronic/layout/core'
import {ThemeModeProvider} from '@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {MasterInit} from '@metronic/_metronic/layout/MasterInit'
import {MasterLayoutDefault} from "@metronic/_metronic/layout/LayoutsVariations/MasterLayoutDefault.tsx"
import {ModalProvider} from "@metronic/Components/Modal"
import {toast, ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {usePage} from "@inertiajs/react";
import _ from "lodash";

const MainDefault = ({children}) => {
  const {props} = usePage()
  const flash = props.flash

  useEffect(() => {
    console.log('general props', props);
    if (_.size(props.errors)) {
      for (let error in props.errors) {
        toast.error(props.errors[error])
      }
    } else if (flash) {
      toast.success(flash.message)
    }
  }, [props])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <ModalProvider>
                <MasterLayoutDefault>
                  {children}
                </MasterLayoutDefault>
                <ToastContainer />
                <MasterInit />
              </ModalProvider>
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
    </Suspense>
  )
}

export default MainDefault

import React from "react"
import {PageTitle} from "@metronic/_metronic/layout/core"
import {MyWalletContent} from "@metronic/Components"
import {usePage} from "@inertiajs/react";

const MyWallet = () => {
  const {props} = usePage()

  return (
    <>
      <PageTitle breadcrumbs={[]}>My Wallet</PageTitle>
      <MyWalletContent/>
    </>
  )
}

export default MyWallet

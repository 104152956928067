import React from 'react'
import {SupportContent} from "@metronic/Components"
import {PageTitle} from "@metronic/_metronic/layout/core"

const Support = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Support</PageTitle>
      <SupportContent />
    </>
  )
}

export default Support

import React, { createContext, useContext, useState } from 'react'

const TabContext = createContext({})

export const useTabContext = () => {
  return useContext(TabContext)
}

export const TabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('overview')

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </TabContext.Provider>
  )
}

import React from "react"
import {MenuItem} from '../MenuItem'
import {MenuInnerWithSub} from "../MenuInnerWithSub"

export function MenuInnerLight() {
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES
  return (
    <>

      <MenuInnerWithSub
        title='Deposit'
        to='#'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='deposit.credit-card' title='Credit Card' hasBullet={true} />
        <MenuItem to='deposit.crypto' title='Crypto' hasBullet={true} />
        <MenuItem to='deposit.bank-details' title='Bank Details' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Withdrawal'
        to='#'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='withdrawal.crypto' title='Crypto' hasBullet={true} />
        <MenuItem to='withdrawal.bank-transfer' title='Bank Transfer' hasBullet={true} />
      </MenuInnerWithSub>

      {(showSecurities === 'true')  &&
        <MenuInnerWithSub
          title='Securities'
          to='#'
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='securities.index' title='Bonds' hasBullet={true} />
        </MenuInnerWithSub>
      }
    </>
  )
}

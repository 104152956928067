import React, {useEffect, useState} from 'react'
import {Button, Form} from "reactstrap"
import bgLight from '@metronic/_metronic/assets/media/auth/bg6.jpg'
import asideImg from '@metronic/_metronic/assets/media/auth/22.png'
import {useForm, usePage} from "@inertiajs/react"
import _ from "lodash"
import {toast, ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {clsx} from "clsx";

const bgPosition = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundSize: "cover",
  zIndex: '0'
}

const overlay = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: '1'
}

const btnPrimary = {
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '4px',
  transition: 'background-color 0.3s',
}

const btnPrimaryHover = {
  backgroundColor: '#0056b3',
}

const btnClicked = {
  backgroundColor: '#7db9f4',
  color: 'white',
}

const logosUrl = '/storage/brand_logos/'
const logoDarkUrl = logosUrl + 'logo-dark.png'

const LogInContent = () => {
  const {props} = usePage()
  const flash = props.flash
  const [isMouseOnButton, setIsMouseOnButton] = useState(false)
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const [isPasswordShaw, setIsPasswordShaw] = useState(false)
  const { data, setData, post, processing, errors } = useForm({
    email: '',
    password: '',
    remember: false,
  })

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    post('/login')

    btn.disabled = false
    setIsButtonClicked(false)
  }

  useEffect(() => {
    // console.log('general props', props);
    if (_.size(props.errors)) {
      for (let error in props.errors) {
        toast.error(props.errors[error])
      }
    } else if (flash) {
      toast.success(flash.message)
    }
  }, [props])

  return (
    <>
      <ToastContainer />
      <div className="d-flex flex-column flex-lg-row flex-column-fluid position-relative">
        <img
          alt='bg'
          className="img-fluid theme-light-show w-100 h-100"
          src={bgLight}
          style={bgPosition}
        />
        <div style={overlay}>
        </div>
        <div
          className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 z-index-1">
          <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
              <div className="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
                <Form onSubmit={e => submitHandler(e)} className="form w-100">
                  <div className="text-center text-gray-900 fw-bolder pt-20 pb-20">
                    <h1>Welcome back!</h1>
                    <img className="mx-auto mw-100 w-150px w-lg-300px"
                         src={logoDarkUrl} alt=""/>
                  </div>
                  <div className="text-center mb-11">
                    <h2 className="text-gray-900 fw-bolder mb-3">Sign In</h2>
                    <div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
                  </div>
                  {/*<div className="row g-3 mb-9">*/}
                  {/*  <div className="col-md-6">*/}
                  {/*    <a href="#"*/}
                  {/*       className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">*/}
                  {/*      <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3"/>Sign*/}
                  {/*      in with Google</a>*/}
                  {/*  </div>*/}
                  {/*  <div className="col-md-6">*/}
                  {/*    <a href="#"*/}
                  {/*       className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">*/}
                  {/*      <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg"*/}
                  {/*           className="theme-light-show h-15px me-3"/>*/}
                  {/*      <img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg"*/}
                  {/*           className="theme-dark-show h-15px me-3"/>Sign in with Apple</a>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="fv-row mb-8">
                    <input
                      type="text"
                      value={data.email}
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={e => setData('email', e.target.value)}
                    />
                  </div>
                  <div className="fv-row mb-3 position-relative">
                    <input
                      type={clsx(isPasswordShaw ? 'text' : 'password')}
                      value={data.password}
                      placeholder="Password"
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={e => setData('password', e.target.value)}
                    />
                    <Button
                      color="link"
                      onClick={() => setIsPasswordShaw(!isPasswordShaw)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                    >
                      <i
                        className={clsx('bi', !isPasswordShaw ? 'bi-eye-fill' : 'bi-eye-slash-fill', 'pb-1 pe-3 fs-2')}
                        style={{color: '#99a1b7'}}
                      ></i>
                    </Button>
                  </div>
                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div></div>
                    {/*<a href="authentication/layouts/overlay/reset-password.html" className="link-primary">Forgot*/}
                    {/*  Password ?</a>*/}
                  </div>
                  <div className="separator my-14"></div>
                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      style={isButtonClicked ? {...btnPrimary, ...btnClicked} : isMouseOnButton ?
                        { ...btnPrimary, ...btnPrimaryHover } : btnPrimary}
                      onMouseEnter={() => setIsMouseOnButton(true)}
                      onMouseLeave={() => setIsMouseOnButton(false)}
                      onClick={() => setIsButtonClicked(true)}
                    >
                      Sign In
                    </button>
                  </div>
                  {/*<div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?*/}
                  {/*  <a href="authentication/layouts/overlay/sign-up.html" className="link-primary">Sign up</a></div>*/}
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-lg-row-fluid z-index-1 mb-15 mb-lg-0">
          <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
            <img className="mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
                 src={asideImg} alt=""/>
            <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">Fast, Efficient and Productive</h1>
            <div className="text-gray-600 fs-base text-center fw-semibold">In this kind of post,
              <a href="#" className="opacity-75-hover text-primary me-1">the blogger</a>introduces a person they’ve
              interviewed
              <br/>and provides some background information about
              <a href="#" className="opacity-75-hover text-primary me-1">the interviewee</a>and their
              <br/>work following this is a transcript of the interview.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LogInContent

import React from "react"
import { PageTitle } from "@metronic/_metronic/layout/core"
import { ExchangeContent } from "@metronic/Components"

const Exchange = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Exchange</PageTitle>
      <ExchangeContent />
    </>
  )
}

export default Exchange

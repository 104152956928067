import React, { createContext, useContext } from 'react'
import { ModalContext } from "@metronic/Components/Modal/index.jsx"
import DepositDetailsModal from "@metronic/Components/Modal/DepositDetailsModal.jsx"
import TransactionsDetailsModal from "@metronic/Components/Modal/TransactionsDetailsModal.jsx"

const TransactionsModalContext = createContext({
  openTransferDetailsModal: {}
})

const TransactionsModalProvider = ({ children }) => {
  const { openModal } = useContext(ModalContext)

  const openTransferDetailsModal = (transfer) => {
    if (transfer?.address_to && transfer?.type === 'acquisition' && transfer?.status !== 'executed') {
      openModal({
        content: <DepositDetailsModal transfer={transfer} />
      })
    } else {
      openModal({
        title: 'Transaction Details',
        content: <TransactionsDetailsModal selectedTransaction={transfer}/>
      })
    }
  }

  return (
    <TransactionsModalContext.Provider value={{ openTransferDetailsModal }}>
      {children}
    </TransactionsModalContext.Provider>
  )
}

export { TransactionsModalProvider, TransactionsModalContext }

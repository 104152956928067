import React, {useEffect, useState} from 'react'
import {clsx} from "clsx"
import {Link} from "@inertiajs/react"
import {Button, Card, CardBody, Col, Row} from "reactstrap"
import {capitalize} from "lodash"
// Components
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
// Blocks
import {
  GeneralTabs,
  SecuritiesTable
} from "@metronic/Components/Securities/Blocks"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"

// Step IMG
import step1Img from './images/5.png'
import step2Img from './images/4.png'
import step3Img from './images/3.png'

// Doc IMG
import pdfImg from '../../../../../public/media/svg/files/pdf.svg'

//Fake data
import {
  ICON_MAPPER,
  MOST_POPULAR_LIST,
  DESCRIPTIONS,
  VIDEO,
  DOCUMENTS_LIST, INVESTMENT_TEXT, FULL_LIST, PURCHASED_LIST
} from '@metronic/Components/Securities/FakeData'
import {ChartsWidget3} from "@metronic/_metronic/partials/widgets"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"

const defaultStepsSmallStyle = 'd-flex flex-column flex-md-row justify-content-center justify-content-md-start ' +
  'mt-3 mt-sm-0'
const lightStepsSmallStyle = 'd-flex flex-column flex-md-row flex-lg-column flex-xl-row justify-content-center ' +
  'justify-content-md-start justify-content-lg-center justify-content-xl-start mt-3 mt-sm-0'
const defaultStepsStyle = ''
const lightStepsStyle = ''

const SecuritiesContent = () => {
  const {
    activeTab,
    setActiveTab,
    setSecuritiesList,
    setCurrentPage,
    selectedSecurities,
    setSelectedSecurities
  } = useSecuritiesContext()
  const [activeStepTab, setActiveStepTab] = useState('stepTab_1')
  const [isMouseOnCard, setIsMouseOnCard] = useState(0)
  const [activeBondId, setActiveBondId] = useState(0)
  const [activeBond, setActiveBond] = useState({})
  const [investmentValue, setInvestmentValue] = useState(50)
  const [selectedCalcOptions, setSelectedCalcOptions] = useState(0.5)
  const [profit, setProfit] = useState(0)

  const selectOptions = [
    {value: '0.5', label: '6 month'},
    {value: '1', label: '1 year'},
    {value: '1.5', label: '1.5 years'},
    {value: '2', label: '2 years'},
    {value: '2.5', label: '2.5 years'},
    {value: '3', label: '3 years'}
  ];

  const investmentAmountChangeHandler = (e) => {
    setInvestmentValue(e.target.value)
  }

  const selectBondHandler = (item, idx) => {
    setActiveBondId(idx)
    setActiveBond(item)
  }

  const calcProfit = () => {
    let totalProfit = 0.00
    if (activeBondId) {
      const annualYield = Number(activeBond.earn_pct) / 100
      totalProfit = Number(investmentValue) * Math.pow((1 + (Number(annualYield))), Number(selectedCalcOptions)) - Number(investmentValue)
    }

    setProfit(Number(totalProfit).toFixed(2))
  }

  useEffect(() => {
    calcProfit()
  }, [activeBondId, investmentValue, selectedCalcOptions])

  const cardsClasses =
    'p-3 d-flex flex-column card cursor-pointer w-250px w-md-350px p-lg-3 mx-lg-2 w-lg-250px p-xl-5 mx-xl-0 w-xl-325px mx-xxl-1 w-xxl-375px'

  useEffect(() => {
    setSecuritiesList(activeTab === 'tab_2' ? FULL_LIST : PURCHASED_LIST)
    setCurrentPage(1)
    setActiveStepTab('stepTab_1')
    setSelectedSecurities({})
  }, [activeTab])

  const stepsImages = () => {
    return <>
      <img
        className={clsx('mw-200px mw-auto mw-sm-100 ps-0 ps-md-2', activeStepTab === 'stepTab_1' ? 'd-block' : 'd-none')}
        src={step1Img}
        alt=""
      />
      <img
        className={clsx('mw-200px mw-auto mw-sm-100 ps-0 ps-md-2', activeStepTab === 'stepTab_2' ? 'd-block' : 'd-none')}
        src={step2Img}
        alt=""
      />
      <img
        className={clsx('mw-200px mw-auto mw-sm-100 ps-0 ps-md-2', activeStepTab === 'stepTab_3' ? 'd-block' : 'd-none')}
        src={step3Img}
        alt=""
      />
    </>
  }

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="row-cols-1 g-5 g-xl-10 mb-5 mb-xl-10">
          <Col>
            <Card>
              <CardBody>

                {/* BEGIN General tabs */}
                <GeneralTabs/>
                {/* END General tabs */}

                <Row className="g-5 g-xl-10">

                  {/* BEGIN Table */}
                  <SecuritiesTable/>
                  {/* END Table */}

                  {/* BEGIN Bonds details*/}
                  {selectedSecurities?.name ?
                    <Col className="col-12">

                      <Button
                        type="button"
                        className="btn btn-sm btn-light-success px-10 my-5"
                        onClick={() => setSelectedSecurities({})}
                      >
                        <i className="bi bi-chevron-double-left"></i> Back
                      </Button>

                      <ChartsWidget3
                        className="mb-15"
                        name={selectedSecurities.name}
                        type={selectedSecurities.type}
                        price={selectedSecurities.price}
                        pct={selectedSecurities.earn_pct}
                      />

                      <div className="text-nowrap overflow-scroll">
                        <div className="d-flex flex-column flex-sm-row justify-content-between pt-8 pb-3 px-3">
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">The cost of the nominal value</span>
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-800">{selectedSecurities.price}</span>
                        </div>
                        <div className="separator text-gray-600"></div>

                        <div className="d-flex flex-column flex-sm-row justify-content-between pt-8 pb-3 px-3">
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">Coupon value</span>
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-800">26.23 USD</span>
                        </div>
                        <div className="separator text-gray-600"></div>

                        <div className="d-flex flex-column flex-sm-row justify-content-between pt-8 pb-3 px-3">
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">Coupon payment date</span>
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-800">{selectedSecurities.price}</span>
                        </div>
                        <div className="separator text-gray-600"></div>

                        <div className="d-flex flex-column flex-sm-row justify-content-between pt-8 pb-3 px-3 text-nowrap">
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">Maturity date of the bond</span>
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-800">{selectedSecurities.repayment_date}</span>
                        </div>
                        <div className="separator text-gray-600"></div>

                        <div className="d-flex flex-column flex-sm-row justify-content-between pt-8 pb-3 px-3">
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">Coupon payment frequency</span>
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-800">134.00</span>
                        </div>
                        <div className="separator text-gray-600"></div>

                        <div className="d-flex flex-column flex-sm-row justify-content-between pt-8 pb-3 px-3">
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">Accumulated coupon income</span>
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-800">14.28 USD</span>
                        </div>
                        <div className="separator text-gray-600"></div>

                        <div className="d-flex flex-column flex-sm-row justify-content-between pt-8 pb-3 px-3">
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-600 min-w-250px">Coupon rate</span>
                          <span className="fs-5 fs-sm-3 fw-bold text-gray-800">{(Number(selectedSecurities.earn_pct) - 3.5).toFixed(2)} %</span>
                        </div>
                        <div className="separator text-gray-600 mb-5"></div>
                      </div>
                    </Col> : null
                  }
                  {/* END Bonds details*/}

                  {/* BEGIN Descriptions block */}
                  <Col className={clsx('', activeTab !== 'tab_1' && 'd-none',
                    VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-6' : 'col-12 col-lg-6'
                  )}>
                    <h1 className="text-gray-700">{DESCRIPTIONS.title}</h1>
                    <div className="separator mb-4 border-1 border-gray-500"></div>
                    {DESCRIPTIONS.text.map((text, idx) => (
                      <p style={{textAlign: 'justify'}} className="fs-5 fw-bold text-gray-700"
                         key={idx}>{text.clause}</p>
                    ))}
                  </Col>
                  {/* END Descriptions block */}

                  {/* BEGIN Video block */}
                  <Col className={clsx('d-flex justify-content-center align-items-center',
                    activeTab !== 'tab_1' && 'd-none',
                    VITE_APP_VARIANT === 'light' ? 'col-12 col-xxl-6' : 'col-12 col-lg-6')}>
                    <VIDEO/>
                  </Col>
                  {/* END Video block */}
                </Row>

              </CardBody>
            </Card>
          </Col>

          <Col className={clsx('', activeTab !== 'tab_1' && 'd-none')}>
            <Card>
              <CardBody>
                <h2 className="text-gray-700">Popular</h2>

                <div className="separator mb-4 border-1 border-gray-500"></div>

                {/* BEGIN Popular cards */}
                <div className="scroll-x overflow-auto d-flex flex-row gap-5 gap-xl-4 gap-xxl-5 pb-5 pt-3 px-3">
                  {MOST_POPULAR_LIST.map((item, idx) => {
                    const id = idx + 1
                    return (
                      <div key={idx}>
                        <Card
                          className={clsx('', cardsClasses,
                            isMouseOnCard === id && 'bg-light-primary shadow-sm',
                            activeBondId === id && 'shadow-sm bg-light-success'
                          )}
                          onClick={() => selectBondHandler(item, id)}
                          onMouseEnter={() => setIsMouseOnCard(id)}
                          onMouseLeave={() => setIsMouseOnCard(0)}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column">
                              <span className="text-muted fs-7 pb-2 fw-bold">{capitalize(item.type)}</span>
                              <h4 className="text-gray-700">{item.name}</h4>
                            </div>
                            <div>
                              <div className="symbol symbol-50px symbol-circle shadow">
                                <img src={ICON_MAPPER[item.icon]} alt="image"/>
                              </div>
                            </div>
                          </div>

                          <div className={clsx('separator mt-4 mb-3 w-75',
                            isMouseOnCard === id && 'border-primary opacity-25',
                            activeBondId === id && 'border-primary opacity-25'
                          )}></div>

                          <div className="d-flex flex-column mb-4">
                            <span className="text-muted fs-7 fw-bold pb-1">Repayment date</span>
                            <span className="text-gray-700 fs-6 fw-bold">{item.repayment_date}</span>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column">
                              <span className="text-muted fs-7 fw-bold pb-1">Yield for the 1 year period</span>
                              <span className="text-gray-700 fs-6 fs-lg-7 fs-xl-6 fw-bold">{item.earn_pct} %</span>
                            </div>

                            <div className="d-flex flex-column">
                              <span className="text-muted fs-7 fw-bold pb-1">Price</span>
                              <span className="text-gray-700 fs-6 fs-lg-7 fs-xl-6 fw-bold">{item.price}</span>
                            </div>
                          </div>
                        </Card>
                      </div>

                    )
                  })}
                </div>
                {/* END Popular cards */}

                {/* BEGIN All list link */}
                <div className="py-3 mb-5">
                  <a
                    href="#"
                    className="text-success fs-4 btn btn-active-light-success ps-2 pe-0 py-0 m-0"
                    onClick={() => setActiveTab('tab_2')}
                  >
                    List of all bonds <i className="bi bi-chevron-right fs-4 ps-1 text-success"></i>
                  </a>
                </div>
                {/* END All list link */}

                {/* BEGIN Calculation block */}
                <Row className="g-5 g-xl-10 d-flex align-items-center">
                  <Col className={clsx('', VITE_APP_VARIANT === 'light' ?
                    'col-12 col-xxl-6' : 'col-12 col-lg-6')}>
                    <Card>
                      <CardBody className="p-0 pt-4">
                        <div className="d-flex flex-column justify-content-end">
                          <label className="d-flex justify-content-between pb-3 px-2">
                            <div className="text-gray-700 fs-3 fw-bold pe-2">Amount you want to invest</div>
                            <div className="text-gray-800 fs-3 fw-bold">{Number(investmentValue).toFixed(2)} USD</div>
                          </label>
                          <input
                            id="range-slider"
                            type="range"
                            min="50"
                            max="50000"
                            value={investmentValue}
                            onChange={investmentAmountChangeHandler}
                            className="custom-range-slider"
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col className={clsx('h-60px', VITE_APP_VARIANT === 'light' ?
                    'col-12 col-xxl-6' : 'col-12 col-lg-6')}>
                    <Card className="h-100">
                      <CardBody className="p-0 pt-5 h-100">
                        <div className="d-flex justify-content-between px-2">
                          <span className="text-gray-700 text-nowrap fs-3 fw-bold">
                            Investment period
                          </span>
                          <select
                            className="text-gray-700 fw-bold fs-4 w-100 ms-5 cursor-pointer border-0 bg-body"
                            onChange={(e) => setSelectedCalcOptions(Number(e.target.value))}
                          >
                            {selectOptions.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col className="col-12">
                    <h2 className="text-gray-700 mb-5">Your profit</h2>
                    <div className="d-column d-sm-flex justify-content-sm-between border rounded-2 p-3 ">
                      <div className="d-flex flex-column">
                        <span className="text-gray-600 fs-5">{activeBond.type ? 'Bonds' : 'Type'}</span>
                        <h2 className="pe-2">{activeBond.name ? activeBond.name : 'Nothing selected'}</h2>
                      </div>

                      <div className="d-flex flex-column">
                        <span className="text-gray-600 fs-5 pe-2">Annualized return</span>
                        <h2>{activeBond.earn_pct ? activeBond.earn_pct : '0.00'} %</h2>
                      </div>

                      <div className="d-flex flex-column">
                        <span className="text-gray-600 fs-5 pe-2">Investment period</span>
                        <h2>{selectedCalcOptions === 0.5 ? '6 month' : selectedCalcOptions} {(Number(selectedCalcOptions) > 1 && selectedCalcOptions !== 0.5) ? 'years' :
                          selectedCalcOptions === 0.5 ? '' : 'year'}</h2>
                      </div>

                      <div className="d-flex flex-column pe-2">
                        <span className="text-gray-600 fs-5">Your potential income</span>
                        <h2>{profit} USD</h2>
                      </div>

                    </div>
                  </Col>

                  <Col className="col-12 mt-3">
                    <p className="text-muted p-3 bg-light-warning rounded-2">
                      <span className="fw-bold">Disclaimer:</span> The calculations provided by this tool are
                      approximate and
                      intended for informational purposes only. The actual returns on your investments may vary
                      from the estimates given, based on various factors and market conditions.
                      Please consult with a financial advisor for more accurate and personalized advice.
                    </p>
                  </Col>
                </Row>
                {/* END Calculation block */}
              </CardBody>
            </Card>
          </Col>

          {/* BEGIN How to buy block */}
          <Col className={clsx('', activeTab === 'tab_3' && 'd-none')}>
            <Card>
              <CardBody className="d-flex flex-column">
                <h2 className="text-gray-700">How to buy bonds?</h2>

                <div className="separator mb-4 border-1 border-gray-500"></div>

                <div className="d-flex">
                  <div
                    className={clsx('min-h-350px d-flex flex-column justify-content-between bg-light p-5 rounded-3',
                      VITE_APP_VARIANT === 'light' ? 'col-12 col-md-7 col-lg-12 col-xl-7 col-xl-8' : 'col-12 col-md-7 col-xl-8'
                      )}>
                    <div className="mt-5 pb-10">
                      <ul className="nav nav-tabs nav-pills border-transparent border-0 mb-5 fs-6">
                        <li className="nav-item cursor-pointer fs-3 fw-bold border-0">
                          <a
                            onClick={() => setActiveStepTab('stepTab_1')}
                            className={clsx('nav-link me-0 me-sm-6 p-0 px-3', activeStepTab === 'stepTab_1' && 'active')}
                            data-bs-toggle="tab"
                          >
                            Step 1
                          </a>
                        </li>
                        <li className="nav-item cursor-pointer fs-3 fw-bold border-0">
                          <a
                            onClick={() => setActiveStepTab('stepTab_2')}
                            className={clsx('nav-link me-0 me-sm-6 p-0 px-3', activeStepTab === 'stepTab_2' && 'active')}
                            data-bs-toggle="tab"
                          >
                            Step 2
                          </a>
                        </li>
                        <li className="nav-item cursor-pointer fs-3 fw-bold border-0">
                          <a
                            onClick={() => setActiveStepTab('stepTab_3')}
                            className={clsx('nav-link me-0 me-sm-6 p-0 px-3', activeStepTab === 'stepTab_3' && 'active')}
                            data-bs-toggle="tab"
                          >
                            Step 3
                          </a>
                        </li>
                      </ul>
                    </div>

                    {
                      activeStepTab === 'stepTab_1' &&
                      <div>
                        <h3 className="text-gray-800 mb-5">Choose your investment.</h3>
                        <p className="fs-3 text-gray-700">Select from a variety of financial instruments such as stocks,
                          bonds,
                          or mutual funds, each offering different levels of risk and return.</p>
                        <p className="fs-3 text-gray-700">Consider your financial
                          goals, risk tolerance, and investment horizon when making your decision.</p>
                      </div>
                    }

                    {
                      activeStepTab === 'stepTab_2' &&
                      <div>
                        <h3 className="text-gray-800 mb-5">Place your order.</h3>
                        <p className="fs-3 text-gray-700">Execute your investment decision by either contacting a
                          licensed
                          broker who
                          can facilitate the transaction for you, providing expert advice if needed, or by utilizing an
                          online platform where you can directly input your investment preferences and complete the
                          transaction swiftly and securely.</p>
                      </div>
                    }

                    {
                      activeStepTab === 'stepTab_3' &&
                      <div>
                        <h3 className="text-gray-800 mb-5">Confirm and track.</h3>
                        <p className="fs-3 text-gray-700">After placing your order, confirm the purchase details to
                          ensure
                          accuracy.</p>
                        <p className="fs-3 text-gray-700">Monitor your investments regularly to stay informed about
                          their
                          performance.</p>
                        <p className="fs-3 text-gray-700">Use financial tools and reports to track progress toward your
                          investment goals.</p>
                      </div>
                    }

                    <div
                      className="mt-0 mt-md-10 d-flex align-items-center justify-content-evenly
                      justify-content-md-start flex-column flex-sm-row"
                    >
                      <div className={clsx('justify-content-center',
                        VITE_APP_VARIANT === 'light' ? 'd-flex d-md-none d-lg-flex d-xl-none' : 'd-flex d-md-none'
                        )}>
                        {stepsImages()}
                      </div>
                      <div
                        className={clsx('text-nowrap', VITE_APP_VARIANT === 'light' ?
                          lightStepsSmallStyle : defaultStepsSmallStyle
                        )}>
                        <Button type="button" className="btn btn-success px-md-3 py-md-1 me-0 me-md-3">Сontact an
                          agent</Button>
                        <Button
                          type="button"
                          className={clsx('btn btn-success',
                            VITE_APP_VARIANT === 'light' ?
                              'px-md-3 py-md-1 mt-2 mt-sm-5 mt-md-0 mt-lg-3 mt-xl-0' :
                              'px-md-3 py-md-1 mt-2 mt-sm-5 mt-md-0'
                            )}>
                          Place an order to buy
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={clsx('justify-content-center align-items-center',
                    VITE_APP_VARIANT === 'light' ?
                      'd-none d-md-flex d-lg-none d-xl-flex col-md-5 col-xl-4' :
                      'd-none d-md-flex col-md-5 col-xl-4'
                    )}>
                    {stepsImages()}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* END How to buy block */}

          {/* BEGIN Documents and information block */}
          <Col className={clsx('', activeTab === 'tab_3' && 'd-none')}>
            <Card>
              <CardBody>
                <h2 className="text-gray-700">Documents and informations</h2>

                <div className="separator mb-4 border-1 border-gray-500"></div>

                <div className="accordion accordion-icon-toggle" id="kt_accordion_2">
                  <div className="mb-5">
                    <div
                      className="accordion-header py-3 d-flex"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_2_item_1"
                    >
                      <h3 className="fs-4 text-gray-800 fw-bold mb-0 me-4">
                        Documents list
                      </h3>
                      <span className="accordion-icon">
                      <i className="bi bi-chevron-double-right"></i>
                    </span>
                    </div>
                    <div
                      id="kt_accordion_2_item_1"
                      className="fs-6 collapse show ps-10"
                      data-bs-parent="#kt_accordion_2"
                    >
                      <div className="d-flex pt-5 flex-column overflow-scroll">
                        {DOCUMENTS_LIST.map((item, idx) => {
                          return (
                            <div className="text-gray-800 fs-4 fw-bold d-flex align-items-center me-10 cursor-pointer"
                                 key={idx}>
                              <div className='symbol symbol-30px me-3'>
                                <img src={pdfImg} alt='#'/>
                              </div>
                              <div>{item.title}</div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>


                <h3 className="fs-4 text-gray-800 fw-bold mb-0 my-5 py-5">
                  Informations
                </h3>
                <div className="bg-light p-10 rounded-3" style={{textAlign: 'justify'}}>
                  {INVESTMENT_TEXT.map((item, idx) => {
                    return (
                      <span key={idx} className="fs-6 me-2 pb-3">{item.sentence}</span>
                    )
                  })}

                  <br/>
                  <br/>
                  {INVESTMENT_TEXT.map((item, idx) => {
                    return (
                      <span key={idx} className="fs-6 me-3">{item.sentence}</span>
                    )
                  })}
                  <br/>
                  <br/>
                  {INVESTMENT_TEXT.map((item, idx) => {
                    return (
                      <span key={idx} className="fs-6 me-3">{item.sentence}</span>
                    )
                  })}
                </div>

              </CardBody>
            </Card>
          </Col>
          {/* END Documents and information block */}
        </Row>
      </Content>
    </>
  )
}

export default SecuritiesContent

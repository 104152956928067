import React, {useEffect, useState} from 'react'
import {Link, router, usePage} from "@inertiajs/react"
import {Tooltip} from "react-tooltip"
import defaultAvatar from '@metronic/assets/images/users/user-dummy-img.jpg'
import {KTIcon} from "@metronic/_metronic/helpers"
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap"
import {capitalize} from "lodash"
import {clsx} from "clsx"

const AsideUserMenuLight = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const currentUser = usePage().props.auth.user
  const [currentAvatar, setCurrentAvatar] = useState(defaultAvatar)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const logout = () => {
    router.visit('/logout', {
      method: 'post'
    })
  }

  useEffect(() => {
    if (currentUser.profile?.avatar_url) {
      setCurrentAvatar(currentUser.profile.avatar_url)
    } else {
      setCurrentAvatar(defaultAvatar)
    }
  }, [currentUser])

  return (
    <>
      <Dropdown
        isOpen={isDropdownOpen}
        toggle={toggleDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon"
        >
          <div className="symbol symbol-50px symbol-circle me-5 border border-1">
            <img className="object-fit-cover" alt="User avatar" src={currentAvatar}/>
          </div>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-lg py-0">
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px symbols-circle me-5 border border-1'>
                <img className="object-fit-cover" alt="User avatar" src={currentAvatar}/>
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-bold d-flex fs-5'>
                  {currentUser?.name}
                </div>
                <span className='fw-semibold text-muted fs-7'>
              {currentUser?.email}
            </span>
              </div>
            </div>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-5' onClick={() => setIsDropdownOpen(false)}>
            <Link href={'/profile'} className='menu-link px-5'>
              My Profile
            </Link>
          </div>

          <div className='separator my-2'></div>

          {/*<Languages/>*/}

          <div className='menu-item px-5 pb-3'>
            <a onClick={logout} className='menu-link px-5'>
              Sign Out
            </a>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default AsideUserMenuLight

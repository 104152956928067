import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {SecuritiesContent} from "@metronic/Components"
import {SecuritiesProvider} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"

const Bonds = (props) => {
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES

  return (
    (showSecurities === 'true') ?
    <>
      <PageTitle breadcrumbs={[]}>Bonds</PageTitle>
      <SecuritiesProvider {...props}>
        <SecuritiesContent />
      </SecuritiesProvider>
    </> :
    <>
    </>
  )
}

export default Bonds

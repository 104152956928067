import React, {useContext, useEffect, useState} from 'react'
import {usePage} from "@inertiajs/react";
import {useTranslation} from "react-i18next"
import {
  cryptoIconsMapper,
  isTwoPrecision,
  prettyDate,
  toTwoPrecision,
  TransactionBadgeIcon
} from "@metronic/helpers/utils.jsx"
import {capitalize} from "lodash"
import {TransactionsModalContext} from "@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx"

const Last5Transactions = () => {
  const {props: pageProps} = usePage()
  const {t} = useTranslation()
  const [data, setData] = useState([])
  const {openTransferDetailsModal} = useContext(TransactionsModalContext)


  useEffect(() => {
    if (pageProps?.last_transactions) {
      setData(pageProps?.last_transactions)
    }
  }, [pageProps])

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label text-gray-700 fw-bold fs-3 mb-1'>Last 5 transactions</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
              <tr className='fw-bold fs-5 text-gray-700 bg-light'>
                <th className='ps-4 min-w-100px rounded-start fw-bold fs-5'>ID</th>
                <th className='min-w-100px fw-bold fs-5'>Type</th>
                <th className='min-w-150px fw-bold fs-5'>Created date</th>
                <th className='min-w-150px fw-bold fs-5'>Base Asset</th>
                <th className='min-w-150px fw-bold fs-5'>Quote Asset</th>
                <th className='min-w-100px fw-bold fs-5'>Amount</th>
                <th className='min-w-150px fw-bold fs-5'>USD Amount</th>
                <th className='min-w-100px fw-bold fs-5'>Fee Pct</th>
                <th className='min-w-100px rounded-end fw-bold fs-5'>Status</th>
              </tr>
              </thead>

              <tbody>
              {data ? (data?.length ?
                  data.map((item, index) => {
                    return (
                      <tr key={index} className="align-middle">
                        <td className="fw-bold text-primary">
                          <a className="cursor-pointer"
                             onClick={() => openTransferDetailsModal(item)}>#{item.id}</a>
                        </td>
                        <td>
                          <TransactionBadgeIcon
                            transactionId={item.id}
                            transactionType={item.type}
                            invoiceId={item.invoice_id}
                            invoiceAmount={item.usd_amount}
                          />
                        </td>
                        <td className="fw-bold">{prettyDate(item.created_at)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="h-25px w-25px rounded-circle me-2">
                              <img
                                src={cryptoIconsMapper[item.base_asset.currency.code]}
                                className="img-fluid"
                                alt="3"
                              />
                            </div>
                            <span className="fw-bold">{item.base_asset.currency.code}</span>
                          </div>
                        </td>
                        <td className="fw-bold">{item.quote_asset?.currency ?
                          <div className="d-flex align-items-center">
                            <div className="h-25px w-25px rounded-circle me-2">
                              <img
                                src={cryptoIconsMapper[item.quote_asset.currency.code]}
                                className="img-fluid"
                                alt="3"
                              />
                            </div>
                            {item.quote_asset.currency.code}
                          </div> : '-'}
                        </td>
                        <td className="fw-bold">{isTwoPrecision(item.base_asset.currency.code, item.base_amount)}</td>
                        <td className="fw-bold">${toTwoPrecision(item.usd_amount)}</td>
                        <td className="fw-bold">{(Number(item.fee_pct) * 100).toFixed(1) * 1}%</td>
                        <td>
                          <span className={'badge badge-light-' + item.status_color}>
                            {t(`${capitalize(item.status)}`)}
                            {item.status === 'pending' ? (
                              <i className="bi bi-clock fs-8 align-bottom ms-2"></i>
                            ) : (item.status === 'executed' ? (
                              <i className="bi bi-check2-circle align-bottom ms-2"></i>
                            ) : (item.status === 'declined' ?
                                <i className="bi bi-exclamation-circle fs-8 align-bottom ms-2"></i> : ''
                            ))}
                          </span>
                        </td>
                      </tr>
                    )
                  }) : (<tr>
                    <td colSpan={11} className='text-center'>{t("No data")}</td>
                  </tr>))
                : (<tr>
                  <td colSpan={11} className='text-center'>{t("Loading")}...</td>
                </tr>)
              }
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Last5Transactions

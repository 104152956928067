import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {NotificationsContent} from "@metronic/Components"

const Notifications = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Notifications</PageTitle>
      <NotificationsContent />
    </>
  )
}

export default Notifications

import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {TransactionsContent} from "@metronic/Components"

const Transactions = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Transactions</PageTitle>
      <TransactionsContent />
    </>
  )
}

export default Transactions

import React from "react"
import {img_1, img_9, img_13, img_16, img_15, img_2, img_14, img_12, img_3, img_4, img_11, img_5, img_6, img_8, img_10} from './images'

export const ICON_MAPPER = {
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
  img_6,
  img_8,
  img_9,
  img_10,
  img_11,
  img_12,
  img_13,
  img_14,
  img_15,
  img_16
}

export const DESCRIPTIONS = {
  title: 'What are bonds?',
  text: [
    {clause: 'Bonds are debt securities issued by governments, municipalities, or corporations to raise capital.'},
    {clause: 'When you buy a bond, you are essentially lending money to the issuer in exchange for periodic interest payments and the return of the bond\'s face value when it matures.'},
    {clause: 'Bonds are considered lower risk compared to stocks, making them a popular investment for income-focused investors.'},
    {clause: 'Key types include treasury bonds, municipal bonds, and corporate bonds.'},
    {clause: 'Interest rates, credit risk, and maturity are critical factors affecting bond prices and yields.'},
    {clause: 'Bonds can be bought through brokers, banks, or directly from the issuer.'}
  ]
}

export const VIDEO = () => {
  return (
    <iframe width="500" height="300" src="https://www.youtube.com/embed/vAdn7aLHpO0?si=gFQHYW8ytgJBgQ7t"
            title="YouTube video player" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="pt-10"
    ></iframe>
  )
}

export const MOST_POPULAR_LIST = [
  {type: 'bonds', name: '017B-PTON23', repayment_date: '22.11.2026', earn_pct: '17.30', price: '850.50 USD', icon: 'img_1'},
  {type: 'bonds', name: '018C-SNOW30', repayment_date: '17.07.2025', earn_pct: '14.60', price: '690.00 USD', icon: 'img_2'},
  {type: 'bonds', name: '019D-DKNG28', repayment_date: '09.02.2027', earn_pct: '18.90', price: '920.30 USD', icon: 'img_3'},
  {type: 'bonds', name: '020E-UUUU26', repayment_date: '28.10.2026', earn_pct: '16.40', price: '780.80 USD', icon: 'img_4' },
  { type: 'bonds', name: '021F-BYND25', repayment_date: '13.06.2025', earn_pct: '12.70', price: '630.25 USD', icon: 'img_5' },
  { type: 'bonds', name: '022G-PLTR29', repayment_date: '01.09.2027', earn_pct: '21.00', price: '1050.00 USD', icon: 'img_6' },
  { type: 'bonds', name: '023H-ARKG27', repayment_date: '16.03.2026', earn_pct: '15.90', price: '760.00 USD', icon: 'img_10' },
  { type: 'bonds', name: '024I-ARKK24', repayment_date: '24.07.2025', earn_pct: '13.40', price: '670.50 USD', icon: 'img_8' },
  { type: 'bonds', name: '025J-TDOC22', repayment_date: '04.11.2026', earn_pct: '19.30', price: '940.20 USD', icon: 'img_9' }
]

export const FULL_LIST = [
  { type: 'bonds', name: '002P-RITR44', repayment_date: '27.05.2026', earn_pct: '16.72', price: '653.56 USD', icon: 'img_10' },
  { type: 'bonds', name: '005X-AMZN27', repayment_date: '15.09.2025', earn_pct: '12.15', price: '732.40 USD', icon: 'img_4' },
  { type: 'bonds', name: '009Y-GOOG21', repayment_date: '08.12.2024', earn_pct: '10.80', price: '550.20 USD', icon: 'img_12' },
  { type: 'bonds', name: '003Z-APPL32', repayment_date: '03.04.2027', earn_pct: '18.30', price: '820.75 USD', icon: 'img_14' },
  { type: 'bonds', name: '007W-MSFT19', repayment_date: '20.11.2025', earn_pct: '14.50', price: '600.90 USD', icon: 'img_3' },
  { type: 'bonds', name: '001Q-TSLA18', repayment_date: '12.08.2026', earn_pct: '15.60', price: '705.25 USD', icon: 'img_9' },
  { type: 'bonds', name: '004R-SPCE25', repayment_date: '31.01.2027', earn_pct: '17.85', price: '890.00 USD', icon: 'img_6' },
  { type: 'bonds', name: '006S-NFLX22', repayment_date: '25.06.2025', earn_pct: '13.75', price: '550.50 USD', icon: 'img_11' },
  { type: 'bonds', name: '008T-ROKU20', repayment_date: '18.12.2026', earn_pct: '16.25', price: '720.80 USD', icon: 'img_15' },
  { type: 'bonds', name: '010U-UBER29', repayment_date: '07.03.2027', earn_pct: '19.20', price: '950.00 USD', icon: 'img_8' },
  { type: 'bonds', name: '011V-AMC27', repayment_date: '14.09.2026', earn_pct: '15.45', price: '800.30 USD', icon: 'img_10' },
  { type: 'bonds', name: '012W-SQ28', repayment_date: '02.11.2025', earn_pct: '12.90', price: '620.50 USD', icon: 'img_1' },
  { type: 'bonds', name: '013X-PINS25', repayment_date: '19.05.2027', earn_pct: '18.75', price: '890.60 USD', icon: 'img_13' },
  { type: 'bonds', name: '014Y-DOCU24', repayment_date: '30.08.2026', earn_pct: '16.80', price: '770.20 USD', icon: 'img_2' },
  { type: 'bonds', name: '015Z-ZM32', repayment_date: '10.12.2025', earn_pct: '13.25', price: '680.90 USD', icon: 'img_5' },
  { type: 'bonds', name: '016A-ROK21', repayment_date: '05.04.2027', earn_pct: '20.15', price: '1020.00 USD', icon: 'img_6' },
  { type: 'bonds', name: '017B-PTON23', repayment_date: '22.11.2026', earn_pct: '17.30', price: '850.50 USD', icon: 'img_8' },
  { type: 'bonds', name: '018C-SNOW30', repayment_date: '17.07.2025', earn_pct: '14.60', price: '690.00 USD', icon: 'img_16' },
  { type: 'bonds', name: '019D-DKNG28', repayment_date: '09.02.2027', earn_pct: '18.90', price: '920.30 USD', icon: 'img_1' },
  { type: 'bonds', name: '020E-UUUU26', repayment_date: '28.10.2026', earn_pct: '16.40', price: '780.80 USD', icon: 'img_4' },
  { type: 'bonds', name: '021F-BYND25', repayment_date: '13.06.2025', earn_pct: '12.70', price: '630.25 USD', icon: 'img_14' },
  { type: 'bonds', name: '022G-PLTR29', repayment_date: '01.09.2027', earn_pct: '21.00', price: '1050.00 USD', icon: 'img_9' },
  { type: 'bonds', name: '023H-ARKG27', repayment_date: '16.03.2026', earn_pct: '15.90', price: '760.00 USD', icon: 'img_3' },
  { type: 'bonds', name: '024I-ARKK24', repayment_date: '24.07.2025', earn_pct: '13.40', price: '670.50 USD', icon: 'img_5' },
  { type: 'bonds', name: '025J-TDOC22', repayment_date: '04.11.2026', earn_pct: '19.30', price: '940.20 USD', icon: 'img_10' },
  { type: 'bonds', name: '026K-SQ25', repayment_date: '23.05.2027', earn_pct: '17.50', price: '870.80 USD', icon: 'img_12' },
  { type: 'bonds', name: '027L-UPST30', repayment_date: '11.12.2026', earn_pct: '16.70', price: '800.30 USD', icon: 'img_11' },
  { type: 'bonds', name: '028M-BMBL26', repayment_date: '29.08.2027', earn_pct: '20.50', price: '1040.00 USD', icon: 'img_10' },
  { type: 'bonds', name: '029N-MRNA23', repayment_date: '18.04.2025', earn_pct: '14.80', price: '710.50 USD', icon: 'img_2' },
  { type: 'bonds', name: '030O-SE27', repayment_date: '07.10.2026', earn_pct: '18.20', price: '900.60 USD', icon: 'img_15' },
  { type: 'bonds', name: '031P-NVDA31', repayment_date: '26.06.2027', earn_pct: '19.80', price: '980.30 USD', icon: 'img_6' },
  { type: 'bonds', name: '032Q-INTC29', repayment_date: '14.01.2026', earn_pct: '15.50', price: '750.90 USD', icon: 'img_1' },
  { type: 'bonds', name: '033R-AMD24', repayment_date: '03.09.2025', earn_pct: '12.60', price: '640.20 USD', icon: 'img_4' },
  { type: 'bonds', name: '034S-ETSY28', repayment_date: '21.04.2027', earn_pct: '21.20', price: '1070.00 USD', icon: 'img_8' },
  { type: 'bonds', name: '035T-ROKU26', repayment_date: '10.10.2026', earn_pct: '17.90', price: '880.50 USD', icon: 'img_10' },
  { type: 'bonds', name: '036U-PTON25', repayment_date: '30.05.2025', earn_pct: '14.30', price: '680.00 USD', icon: 'img_3' },
  { type: 'bonds', name: '037V-NIO30', repayment_date: '19.12.2026', earn_pct: '19.50', price: '950.20 USD', icon: 'img_13' }
]

export const PURCHASED_LIST = [
  { type: 'bonds', name: '029N-MRNA23', repayment_date: '18.04.2025', earn_pct: '14.80', price: '710.50 USD', icon: 'img_2' },
  { type: 'bonds', name: '030O-SE27', repayment_date: '07.10.2026', earn_pct: '18.20', price: '900.60 USD', icon: 'img_15' },
  { type: 'bonds', name: '031P-NVDA31', repayment_date: '26.06.2027', earn_pct: '19.80', price: '980.30 USD', icon: 'img_6' },
  { type: 'bonds', name: '032Q-INTC29', repayment_date: '14.01.2026', earn_pct: '15.50', price: '750.90 USD', icon: 'img_1' },
  { type: 'bonds', name: '033R-AMD24', repayment_date: '03.09.2025', earn_pct: '12.60', price: '640.20 USD', icon: 'img_4' },
  { type: 'bonds', name: '034S-ETSY28', repayment_date: '21.04.2027', earn_pct: '21.20', price: '1070.00 USD', icon: 'img_8' },
  { type: 'bonds', name: '035T-ROKU26', repayment_date: '10.10.2026', earn_pct: '17.90', price: '880.50 USD', icon: 'img_10' },
  { type: 'bonds', name: '036U-PTON25', repayment_date: '30.05.2025', earn_pct: '14.30', price: '680.00 USD', icon: 'img_3' },
]

export const INVESTMENT_TEXT = [
  { sentence: "Investing in bonds is a popular choice for conservative investors." },
  { sentence: "Bonds offer a fixed return over a specified period of time." },
  { sentence: "They are considered safer than stocks but usually offer lower returns." },
  { sentence: "Before investing in bonds, it's essential to understand the terms and conditions." },
  { sentence: "Different types of bonds include government bonds, corporate bonds, and municipal bonds." },
  { sentence: "Government bonds are typically the safest, backed by the national government's credit." },
  { sentence: "Corporate bonds are issued by companies and usually offer higher yields than government bonds." },
  { sentence: "Municipal bonds are issued by local governments and are often tax-exempt." },
  { sentence: "It's important to diversify your bond investments to spread risk." },
  { sentence: "Consider the bond's credit rating to gauge its risk level." },
  { sentence: "Higher-rated bonds are safer but offer lower returns." },
  { sentence: "Lower-rated bonds, or 'junk bonds', offer higher returns but come with higher risk." },
  { sentence: "Always check the bond's yield to maturity before investing." },
  { sentence: "The yield to maturity indicates the total return you will receive if you hold the bond until it matures." },
  { sentence: "You can buy bonds through a broker or directly from the issuer." },
  { sentence: "Online platforms make it easy to purchase and manage your bond investments." },
  { sentence: "Before buying, review the bond's prospectus for detailed information." },
  { sentence: "Pay attention to the bond's coupon rate, which is the interest rate paid by the bond issuer." },
  { sentence: "The coupon rate can be fixed or variable." },
  { sentence: "Fixed coupon rates provide predictable income." },
  { sentence: "Variable coupon rates can change based on market conditions." },
  { sentence: "Consider the bond's maturity date to match your investment timeline." },
  { sentence: "Short-term bonds mature in less than three years." },
  { sentence: "Medium-term bonds mature in three to ten years." },
  { sentence: "Long-term bonds mature in more than ten years." },
  { sentence: "Interest from bonds is typically paid semi-annually." },
  { sentence: "Reinvesting bond interest payments can enhance your overall return." },
  { sentence: "Be aware of the bond's call provisions." },
  { sentence: "Call provisions allow the issuer to redeem the bond before maturity." },
  { sentence: "Called bonds are usually redeemed at a premium price." },
  { sentence: "Understand the tax implications of bond investments." },
  { sentence: "Interest from government bonds is often exempt from state and local taxes." },
  { sentence: "Municipal bond interest is usually exempt from federal taxes." },
  { sentence: "Corporate bond interest is fully taxable." },
  { sentence: "Consult with a financial advisor to tailor your bond investment strategy." },
  { sentence: "Monitor your bond investments regularly to stay informed about their performance." },
  { sentence: "Consider the impact of inflation on bond returns." },
  { sentence: "Inflation can erode the purchasing power of fixed interest payments." },
  { sentence: "Investing in inflation-protected securities can help mitigate this risk." },
  { sentence: "Diversify your portfolio to include a mix of stocks, bonds, and other assets." },
  { sentence: "This approach helps balance risk and return over the long term." },
  { sentence: "Stay informed about market trends and economic conditions that affect bond prices." },
  { sentence: "Regularly review and adjust your investment strategy to meet your financial goals." },
  { sentence: "Investing in bonds requires careful planning and due diligence." },
  { sentence: "By following these guidelines, you can make informed decisions and build a stable investment portfolio." }
];

export const DOCUMENTS_LIST = [
  { title: "Bond Prospectus" },
  { title: "Annual Financial Report" },
  { title: "Bond Indenture Agreement" },
  { title: "Credit Rating Report" }
]

export const TABS = [
  {
    "id": "tab_name_1",
    "title": "General Information",
  },
  {
    "id": "tab_name_2",
    "title": "List of Available Securities",
  },
  {
    "id": "tab_name_3",
    "title": "My Securities List",
  }
]

import React, {FC, useEffect} from 'react'
import {Footer} from '@metronic/_metronic/layout/components/Footer'
import {HeaderWrapperDefault} from '@metronic/_metronic/layout/components/header/HeaderWrapper/HeaderWrapperDefault'
import {ScrollTop} from '@metronic/_metronic/layout/components/ScrollTop'
import {PageDataProvider} from '@metronic/_metronic/layout/core'
// import {ActivityDrawer, DrawerMessenger, InviteUsers, RightToolbar, UpgradePlan} from '../partials'
import {themeModeSwitchHelper, useThemeMode} from '@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider'
// import {MenuComponent} from '../assets/ts/components'
// import clsx from 'clsx'
import {WithChildren} from '@metronic/_metronic/helpers'
import { AsideDefault } from '@metronic/_metronic/layout/components/aside/Default/AsideDefault'
import {TransactionsModalProvider} from "@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx"

const MasterLayoutDefault: FC<WithChildren> = ({children}) => {
  // const {classes} = useLayout()
  const {mode} = useThemeMode()
  // const location = useLocation()

  // useEffect(() => {
  //   settimeout(() => {
  //     MenuComponent.reinitialization()
  //   }, 500)
  // }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

    return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapperDefault />
            <TransactionsModalProvider>
              {children}
            </TransactionsModalProvider>
          <AsideDefault />
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      {/*<ActivityDrawer />*/}
      {/*<RightToolbar />*/}
      {/*<DrawerMessenger />*/}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/*<InviteUsers />*/}
      {/*<UpgradePlan />*/}
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayoutDefault}

import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {WithdrawalCryptoContent} from "@metronic/Components"

const WithdrawalCrypto = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Withdrawal Crypto</PageTitle>
      <WithdrawalCryptoContent />
    </>
  )
}

export default WithdrawalCrypto

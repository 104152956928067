import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {DepositCreditCardContent} from "@metronic/Components"

const DepositCreditCard = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Deposit Credit Card</PageTitle>
      <DepositCreditCardContent />
    </>
  )
}

export default DepositCreditCard

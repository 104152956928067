import React, {useState} from 'react'
import {Button, Col} from "reactstrap"
import {clsx} from "clsx"
import {ICON_MAPPER} from "@metronic/Components/Securities/FakeData"
import {capitalize} from "lodash"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"

const SecuritiesTable = () => {
  const {
    activeTab,
    securitiesList,
    setSecuritiesList,
    currentPage,
    setCurrentPage,
    selectedSecurities,
    setSelectedSecurities
  } = useSecuritiesContext()
  const [isMouseOnRow, setIsMouseOnRow] = useState(0)
  const recordsPerPage = 10
  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
  const currentRecords = securitiesList.slice(indexOfFirstRecord, indexOfLastRecord)
  const totalPages = Math.ceil(securitiesList.length / recordsPerPage)

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  }
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  }

  return (
    <>
      <Col className={clsx('', (activeTab === 'tab_1' || selectedSecurities?.name)&& 'd-none')}>
        <div className="card-flush">
          <div className="mt-5 mb-5 d-flex justify-content-between">
            <div className="card-title d-flex align-items-center">
              <h3 className="fw-bold mb-1">
                {activeTab === 'tab_2' ? 'List of available securities' : 'My securities list'}
              </h3>
            </div>
            {/*<div className="my-1 d-flex">*/}
            {/*  <div className="me-6 my-1">*/}
            {/*    <select id="kt_filter_year" name="year" data-control="select2" data-hide-search="true"*/}
            {/*            className="form-select fs-6 fw-bold form-select-solid form-select-sm">*/}
            {/*      <option value="All" defaultValue>Search by repayment date</option>*/}
            {/*      <option value="last90days">In 6 month</option>*/}
            {/*      <option value="thisyear">This year</option>*/}
            {/*      <option value="thismonth">Next year</option>*/}
            {/*      <option value="lastmonth">More then 2 years</option>*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*  <div className="me-4 my-1">*/}
            {/*    <select id="kt_filter_orders" name="orders" data-control="select2" data-hide-search="true"*/}
            {/*            className="form-select fs-6 fw-bold form-select-solid form-select-sm">*/}
            {/*      <option value="All" defaultValue>Search by profitability</option>*/}
            {/*      <option value="Approved">0.1% - 10%</option>*/}
            {/*      <option value="Declined">10% - 15%</option>*/}
            {/*      <option value="In Progress">15% - 20%</option>*/}
            {/*      <option value="In Transit">More then 20%</option>*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*  <div className="d-flex align-items-center position-relative my-1">*/}
            {/*    <i className="ki-outline ki-magnifier fs-3 position-absolute ms-3"></i>*/}
            {/*    <input type="text" id="kt_filter_search"*/}
            {/*           className="form-control form-control-solid form-select-sm w-150px ps-9"*/}
            {/*           placeholder="Search by name" />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="pt-0">
            <div className="table-responsive">
              <table id="kt_profile_overview_table"
                     className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                <thead className="fs-7 text-gray-500 text-uppercase">
                <tr>
                  <th className="min-w-250px">Name</th>
                  <th className="min-w-150px">Repayment date</th>
                  <th className="min-w-90px">Profitability</th>
                  <th className="min-w-90px">Price</th>
                </tr>
                </thead>
                <tbody className="fs-6">
                {(currentRecords || []).map((item, idx) => {
                  const id = idx + 1
                  return (
                    <tr key={idx}
                        onClick={() => setSelectedSecurities(item)}
                        onMouseEnter={() => setIsMouseOnRow(id)}
                        onMouseLeave={() => setIsMouseOnRow(0)}
                        className={clsx('', isMouseOnRow === id && 'bg-light')}
                    >
                      <td className="cursor-pointer">
                        <div className="d-flex align-items-center">
                          <div className="me-5 position-relative">
                            <div className="symbol symbol-35px">
                              <img alt="img" src={ICON_MAPPER[item.icon]} />
                            </div>
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <span className="fs-6 text-gray-800">
                              {item.name}
                              {isMouseOnRow === id &&
                                <i className="bi bi-chevron-right text-primary ms-5"></i>
                              }
                            </span>
                            <div className="fw-semibold text-gray-500">{capitalize(item.type)}</div>
                          </div>
                        </div>
                      </td>
                      <td>{item.repayment_date}</td>
                      <td>{item.earn_pct}%</td>
                      <td>{item.price}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <Button type="button" onClick={handlePrevPage} disabled={currentPage === 1}
                      className="btn btn-light-success px-2 py-1 me-5 d-flex justify-content-center align-items-center">
                <i className="bi bi-chevron-double-left"></i>
              </Button>
              <span className="fs-6 fw-bold text-primary">Page {currentPage} of {totalPages}</span>
              <Button type="button" onClick={handleNextPage} disabled={currentPage === totalPages}
                      className="btn btn-light-success px-2 py-1 ms-5 d-flex justify-content-center align-items-center">
                <i className="bi bi-chevron-double-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </>
  )
}

export default SecuritiesTable

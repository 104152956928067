import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {DepositCryptoContent} from "@metronic/Components"

const DepositCrypto = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Deposit Crypto</PageTitle>
      <DepositCryptoContent />
    </>
  )
}

export default DepositCrypto

import React, {useContext, useEffect, useState} from 'react'
import {KTIcon} from "@metronic/_metronic/helpers"
import {copyToClipboard, cryptoIconsMapper, isTwoPrecision, toTwoPrecision} from "@metronic/helpers/utils"
import QRCode from "react-qr-code"
import {Button, Col, Row} from "reactstrap"
import {capitalize} from "lodash"
import {ModalContext} from "@metronic/Components/Modal/ModalsContext/ModalContext"
import {usePage} from "@inertiajs/react";

const DepositDetailsModal = ({transfer}) => {
  const {closeModal} = useContext(ModalContext)
  const {props: pageProps} = usePage()
  const [assetCode, setAssetCode] = useState('')
  const [assetAddress, setAssetAddress] = useState('')
  const [assetAmount, setAssetAmount] = useState('')
  const [usdAmount, setUsdAmount] = useState('')
  const [status, setStatus] = useState('')
  const [statusColor, setStatusColor] = useState('')

  const setupTransfer = (transfer) => {
    setAssetCode(transfer.base_asset.currency.code)
    setAssetAddress(transfer.address_to ?? '')
    setAssetAmount(transfer.base_amount)
    setUsdAmount(transfer.usd_amount)
    setStatus(transfer.status)
    setStatusColor(transfer.status_color)
  }

  useEffect(() => {
    if (transfer) {
      setupTransfer(transfer)
    }
  }, [transfer])

  return (
    <>
      <div>
        <h3 className="card-title text-center fs-2 text-gray-700 pb-3">Deposit details</h3>
      </div>

      <div className="separator"></div>

      <div className="text-center fs-6 text-gray-600 fw-bold py-2">
        Please double-check the transaction details thoroughly, as it won't be possible to
        recover the crypto if it is sent to an incorrect address.
      </div>

      <div className="bg-secondary p-3 rounded-2 fw-bold">
        <div className="fs-6 py-1 text-gray-800">
          Amount in asset:
          <span className="ps-2 fs-5 text-gray-700 cursor-pointer" onClick={() => copyToClipboard(assetAmount)}>
            {isTwoPrecision(assetCode, assetAmount)}
            <KTIcon iconName='copy' className='fs-3 ps-2'/>
          </span>
        </div>

        <div>
          <span className="fs-6 py-1 text-gray-800">
            Amount in USD: ${toTwoPrecision(usdAmount)}
          </span>

        </div>
      </div>

      <div className="text-gray-800 fs-6 fw-bold pt-4 pb-2">
        Send <span className="text-gray-600">{isTwoPrecision(assetCode, assetAmount)} {assetCode}</span> to the following address:
      </div>

      <div
        className="bg-secondary p-3 rounded-2 fw-bold d-flex position-relative cursor-pointer"
        onClick={() => copyToClipboard(assetAddress)}
      >
        <div className='symbol-label me-2' style={{height: '25px'}}>
          <img
            src={cryptoIconsMapper[assetCode]}
            className='h-75 align-self-end'
            alt=''
          />
        </div>
        <span className="fs-5 text-gray-700 pe-3" style={{wordBreak: 'break-all'}}>
          {assetAddress}
        </span>
        <span className="position-absolute" style={{right: '10px', top: '10px'}}>
          <KTIcon iconName='copy' className='fs-3 ps-2'/>
        </span>
      </div>

      <div className='separator d-flex flex-center mt-8 mb-8'>
        <span className='bg-body fw-bold fs-6 fw-semibold text-muted px-3'>or</span>
      </div>

      <Row className="px-3 pb-5">
        <div className="d-flex justify-content-center align-items-center bg-secondary py-4 rounded-2">
          <Col className="col-4">
            <div style={{height: "auto", margin: "0 auto", maxWidth: 200, width: "100%"}}>
              <QRCode
                size={256}
                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                value={assetAddress}
                viewBox={`0 0 256 256`}
              />
            </div>
          </Col>
        </div>
      </Row>

      <div className="separator"></div>

      <div className="py-3 px-5 rounded-2 text-center fw-bold text-gray-600">
        Once we receive the amount in our account, you will be notified of the updated transaction
        status. Until then, the transaction will remain <span className={`badge badge-${statusColor}`}>{capitalize(status)}</span>
      </div>

      <div className="text-center pt-6">
        <Button
          type="button"
          color="primary"
          className="btn w-75 btn-sm btn-primary fw-bold hover-scale"
          onClick={closeModal}
        >
          OK
        </Button>
      </div>

    </>
  )
}

export default DepositDetailsModal

import React, {useEffect, useRef, useState} from 'react'
import clsx from "clsx"
import {CardBody} from "reactstrap"
import defaultAvatar from '@metronic/assets/images/users/user-dummy-img.jpg'
import {copyChatMessage, prettyDate} from "@metronic/helpers/utils.jsx"
import {useForm, usePage} from "@inertiajs/react"

const ChatBody = ({initialMessage, comments, userId, ticketId, isDrawer = false}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState(false)
  const [message, setMessage] = useState('')
  const [messageId, setMessageId] = useState('')
  const [messages, setMessages] = useState([])
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const messagesEndRef = useRef(null)
  const currentUser = usePage().props.auth.user
  const [currentAvatar, setCurrentAvatar] = useState(defaultAvatar)

  const {setData, post} = useForm({comment: ''})

  const sendMessage = () => {

    post(`/support/comment/${ticketId}`, {
      onSuccess() {
        setMessage('')
      }
    })
    // const newMessage = {
    //   user: 2,
    //   type: 'out',
    //   text: message,
    //   time: 'Just now',
    // }
    //
    // bufferMessages.push(newMessage)
    // setMessages(bufferMessages)
    // toggleChatUpdateFlat(!chatUpdateFlag)
    // setMessage('')
    // setTimeout(() => {
    //   bufferMessages.push(messageFromClient)
    //   setMessages(() => bufferMessages)
    //   toggleChatUpdateFlat((flag) => !flag)
    // }, 1000)
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  useEffect(() => {
    if (initialMessage && comments && comments?.length) {
      setMessages([initialMessage, ...comments])
    } else {
      setMessages([initialMessage])
    }
  }, [comments])


  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setWindowHeight(windowHeight)
  }, [windowHeight])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  useEffect(() => {
    if (currentUser.profile?.avatar_url) {
      setCurrentAvatar(currentUser.profile.avatar_url)
    } else {
      setCurrentAvatar(defaultAvatar)
    }
  }, [currentUser])

  return (
    <>
      <CardBody
        className='card-flush pb-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      >
        {initialMessage ?
          <>
            <div
              style={{maxHeight: windowHeight - 527.5}}
              className={clsx('scroll-y me-n5 pe-5', !isDrawer ? "h-300px h-lg-auto" : '')}
              data-kt-element='messages'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: true}'
              // data-kt-scroll-max-height='auto'
              // data-kt-scroll-dependencies={
              //   isDrawer
              //     ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              //     : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              // }
              // data-kt-scroll-wrappers={
              //   isDrawer
              //     ? '#kt_drawer_chat_messenger_body'
              //     : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
              // }
              // data-kt-scroll-offset='5px'
            >
              {messages.map((message, index) => {
                // const userInfo = userInfos[message?.user]
                const state = userId !== message?.user_id ? 'primary' : 'success'
                const templateAttr = {}
                if (message?.message) {
                  Object.defineProperty(templateAttr, 'data-kt-element', {
                    value: `template-${userId !== message?.user_id ? 'in' : 'out'}`,
                  })
                }
                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                  userId !== message?.user_id ? 'start' : 'end'
                } mb-10`
                return (
                  <div
                    key={index}
                    // className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message?.message})}
                    className={clsx('d-flex', contentClass, Number(index) === 0 ? 'flex-column' : '')}
                    {...templateAttr}
                  >
                    {Number(index) === 0 ?
                      <div className='w-100 text-center text-gray-700 fs-5 fw-bold pb-5'>
                        Open {prettyDate(message.created_at)}
                      </div> : null
                    }
                    <div
                      className={clsx(
                        'd-flex flex-column',
                        `align-items-${userId !== message?.user_id ? 'start' : 'end'}`,
                        ((Number(index) === Number(messages.length - 1)) && (Number(initialMessage.status) === 4)) && 'w-100'
                      )}
                    >
                      <div className='d-flex align-items-center mb-2'>
                        {userId !== message?.user_id ? (
                          <>
                            <div className='symbol symbol-35px symbol-circle '>
                              <img alt='Pic' src={defaultAvatar}/>
                            </div>
                            <div className='ms-3 d-flex flex-column'>
                          <span className='fs-6 fw-bold text-gray-900 me-1'>
                            Support Team
                          </span>
                              <span className='text-muted fs-7 mb-1'>{prettyDate(message?.created_at)}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='me-3 d-flex flex-column align-items-end'>
                              <a href='#' className='fs-6 fw-bold text-gray-900 text-hover-primary ms-1'>
                                You
                              </a>
                              <div className='text-muted fs-7 mb-1'>{prettyDate(message?.created_at)}</div>
                            </div>
                            <div className='symbol  symbol-35px symbol-circle '>
                              <img alt='Pic' src={currentAvatar}/>
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        className={clsx('d-flex flex-row', userId !== message?.user_id ? 'flex-row-reverse' : '')}
                        onMouseEnter={() => setMessageId(String(index))}
                        onMouseLeave={() => setMessageId('')}
                      >
                        <span className={clsx('', messageId === String(index) ? 'd-block' : 'd-none')}>
                          {copyChatMessage({text: message.message, title: 'Copy'})}
                        </span>
                        <div
                          className={clsx(
                            'p-5 rounded keep-lines',
                            `bg-light-${state}`,
                            'text-gray-900 fw-semibold mw-lg-400px',
                            `text-${userId !== message?.user_id ? 'start' : 'end'}`
                          )}
                          data-kt-element='message-text'
                          // dangerouslySetInnerHTML={{__html: message?.message}}
                        >
                          {message?.message}
                        </div>
                      </div>
                      {((Number(index) === Number(messages.length - 1)) && (Number(initialMessage.status) === 4)) ?
                        <div className='w-100 text-center text-gray-700 fs-5 fw-bold pt-5'>
                          Closed {prettyDate(message.created_at)}
                        </div> : null
                      }
                    </div>
                  </div>
                )
              })}
              <div ref={messagesEndRef}/>
            </div>

            <div
              className='card-footer px-0 pt-4'
              id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
            >
        <textarea
          className='form-control form-control-flush mb-3'
          disabled={Number(initialMessage.status) === 4}
          rows={1}
          data-kt-element='input'
          placeholder={Number(initialMessage.status) === 4 ?
            'This chat is closed. If you still have questions - create a new chat' :
            'Type a message'
        }
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
            setData('comment', e.target.value)
          }}
          onKeyDown={onEnterPress}
        ></textarea>

              <div className='d-flex justify-content-end pt-3'>
                {/*<div className='d-flex align-items-center me-2'>*/}
                {/*  <button*/}
                {/*    className='btn btn-sm btn-icon btn-active-light-primary me-1'*/}
                {/*    type='button'*/}
                {/*    data-bs-toggle='tooltip'*/}
                {/*    title='Coming soon'*/}
                {/*  >*/}
                {/*    <i className='bi bi-paperclip fs-3'></i>*/}
                {/*  </button>*/}
                {/*  <button*/}
                {/*    className='btn btn-sm btn-icon btn-active-light-primary me-1'*/}
                {/*    type='button'*/}
                {/*    data-bs-toggle='tooltip'*/}
                {/*    title='Coming soon'*/}
                {/*  >*/}
                {/*    <i className='bi bi-upload fs-3'></i>*/}
                {/*  </button>*/}
                {/*</div>*/}
                <button
                  className='btn btn-sm btn-primary'
                  type='button'
                  disabled={Number(initialMessage.status) === 4}
                  data-kt-element='send'
                  onClick={sendMessage}
                >
                  Send
                </button>
              </div>
            </div>
          </> :

          <div className="h-300px d-flex flex-column justify-content-center align-items-center mb-5">
            <h2 className="text-muted text-wrap text-center">Select a ticket from the existing ones</h2>
            <h4 className="text-muted lh-lg">or create a new one</h4>
            <button
              type="btn"
              className="btn btn-light-primary py-1 px-3 mt-5"
              // onClick={() => {
              //   isCreateNewTicketForm()
              //   userChatOpen()
              // }}
            >
              Create new ticket
            </button>
          </div>
        }

      </CardBody>
    </>
  )
}

export default ChatBody
